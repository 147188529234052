import styled from "styled-components";
import { TemplateContainer } from "../styles/styledContainer";


export const StyledAboutUsContainer = styled.div``
export const StyledAboutUsInfoBlock = styled(TemplateContainer)`
  .content-wrapper {
    &__title {
      display: none;
    }
    margin: 0 auto;
    &__paragraph {
      padding: 10px 0;
    }
    @media screen and (max-width: 768px){
      font-size: 16px;
    }
  }
`

