import React from "react";
import { InformationCardWrapper } from "./styledCard";
import { NavLink } from "react-router-dom";

import { StyledButton } from "../../styles/styledButton";

const InformationCard = ({ url, tag, title, description, t }) => {
  return (
    <InformationCardWrapper>
      <div className="information-item">
        <div className="information-item__header">
          <h3 className="information-item__title">
            <span className="information-item__type">
              {tag}.
            </span>
            {title}
          </h3>
        </div>
        <div className="information-item__body">
          <div className="information-item__content">
            {description}
          </div>
          <StyledButton
            as={NavLink}
            to={"information-details/" + url}
            color="main"
            className="information-item__button"
          >
            {t("read_more_btn")}
          </StyledButton>
        </div>
      </div>
    </InformationCardWrapper>
  );
};

export default InformationCard;