import styled from "styled-components";
import Dialog from "rc-dialog";

export const StyledDialog = styled(Dialog)`
  width: 800px;
  padding: 60px 0;
  background: #FFFFFF;
  box-shadow: 0 25px 34px rgba(41, 73, 101, 0.4);
  border-radius: 10px;
  @media screen and (max-width: 992px){
    max-width: 90%;
    margin: 0 auto;
  }
`;