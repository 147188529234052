import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import LangSelect from "../header/LangSelect";
import { useTranslation } from "react-i18next";

import { StyledNavigationsWrapper, StyledNavList, StyledShowBarButton } from "./styledNavigations";

const Navigation = () => {
  const [showBar, setShowBar] = useState(false);
  const { t } = useTranslation("navigation");

  return (
    <>
      <StyledNavigationsWrapper showBar={showBar}>
        <StyledNavList>
          <li>
            <Link
              onClick={() => setShowBar(false)}
              className="menu-link"
              to="/#about_us"
            >
              {t("about_us")}
            </Link>
          </li>
          <li>
            <NavLink
              onClick={() => setShowBar(false)}
              className="menu-link"
              activeClassName="menu-link_active"
              to="/for-client"
            >
              {t("for_client")}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setShowBar(false)}
              className="menu-link"
              activeClassName="menu-link_active"
              to="/information"
            >
              {t("information")}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setShowBar(false)}
              className="menu-link"
              activeClassName="menu-link_active"
              to="/what-we-do"
            >
              {t("what_we_do")}
            </NavLink>
          </li>
          <li>
            <Link
              onClick={() => setShowBar(false)}
              className="menu-link"
              to="/#how-it-works"
            >
              {t("how_it_works")}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setShowBar(false)}
              className="menu-link"
              to="/#reviews"
            >
              {t("reviews_us")}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setShowBar(false)}
              className="menu-link"
              to="/#contacts"
            >
              {t("contacts")}
            </Link>
          </li>
          <li>
            <LangSelect id="langHead"/>
          </li>
        </StyledNavList>
      </StyledNavigationsWrapper>
      <StyledShowBarButton onClick={() => setShowBar(!showBar)}>
        <span className="icon-menu-burger" />
      </StyledShowBarButton>
    </>
  );
};

export default Navigation;