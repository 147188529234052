import styled from "styled-components"
import { StyledContainer, StyledContentWrapper } from "../styles/styledContainer";
import InputGroup from "../elements/inputGroup/InputGroup";

export const StyledForClientWrapper = styled(StyledContainer)`
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
`;

export const StyledForClientContainer = styled(StyledContentWrapper)`
  padding: 0 75px 255px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  border-radius: 10px;
  background: #F2F1F1;
  .content{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .option-select-item{
    font-size: 18px;
  }
  .rc-select-selection-placeholder {
    color: #d6d4d4;
    font-weight: 600;
    font-size: 18px;
  }
  .for-client__form {
    position: relative;
  }
  @media screen and (max-width: 992px){
    padding: 20px;
    margin-top: 20px;
    grid-template-columns: 100%;
    grid-gap: 30px;
  }
`;

export const CustomInput = styled(InputGroup)`
  width: 100%;
  margin-bottom: 20px;
  font-weight: 400;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(41, 73, 101, 0.2);
  z-index: 1;
  input {
    padding: 21px 22px;
    border: none;
    color: #294965;
  }
  &::placeholder{
    color: #294965;
  }
`;