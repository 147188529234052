import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { StyledNotFoundContent, StyledNotFoundWrapper } from "./styled-not-found";

const NotFoundPage = ({ match }) => {
  const { t } = useTranslation("404")


  return (
    <StyledNotFoundWrapper>
      <Helmet>
        <title>{t("title")}</title>
        <meta
          name="robots"
          content="noindex"
        />
      </Helmet>
      <StyledNotFoundContent>
        <b>404.</b>
        <p>
          {t("text")}
        </p>
      </StyledNotFoundContent>
    </StyledNotFoundWrapper>
  );
};

export default React.memo(NotFoundPage);