import styled from "styled-components";

export const StyledNavigationsWrapper = styled.nav`
  height: auto;
  display: flex;
  
  @media screen and (max-width: 992px) {
    ${({ showBar }) => showBar ? `
      display: flex;
      animation: 0.3s ease-in-out 0s alternate showNav;
    ` : `
      display: none;
    `};
    width: 100%;
    position: absolute;
    top: 55px;
    left: 0;
    background-color: #294965;
    z-index: 999;
    overflow: hidden;

    @keyframes showNav {
      from {
        height: 0;
      }
      to {
        height: 330px;
      }
    }
  }
`;

export const StyledNavList = styled.ul`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(8, auto);
  li {
    display: inline-flex;
    align-items: center;
    transition: all .3s ease;
    cursor: pointer;

    .menu-link {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 600;
      transition: all .3s ease;
      &:hover {
        color: #FDDD10;
      }
    }
  }
  
  @media screen and (max-width: 1250px) {
    grid-gap: 15px;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 5px;
    li {
      width: 100%;
      .menu-link {
        padding: 7px 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export const StyledShowBarButton = styled.button`
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  font-size: 28px;
  display: none;
  border: none;
  transition: all .3s ease;
  &:hover {
    color: #FDDD10;
  }
  
  @media screen and (max-width: 992px){
    display: block;
  }
`;