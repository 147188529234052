import { StyledTitleText, StyledTitleWrapper } from "./styledTitleWrapper";

const CustomTitle = ({ title, as, color, align, category, mb, mt }) => {
  return (
    <StyledTitleWrapper mb={mb} mt={mt} align={align}>
      <StyledTitleText color={color} as={as}>
        {category && <span className="category">{category}</span>}
        {title}
      </StyledTitleText>
    </StyledTitleWrapper>
  );
};

export default CustomTitle;