import { TemplateContainer } from "../styles/styledContainer";
import HelmetContainer from "../elements/helmetContainer/HelmetContainer";

const InformationTabContent = ({info_item}) => {
  const content = info_item.content;
  return (
    <HelmetContainer title={info_item.title} description={info_item.description}>
      <TemplateContainer grid="tab">
        <div className="content-wrapper">
          <h1 className="content-wrapper__title">
            {info_item.title}
          </h1>
          <ul className="content-wrapper__content">
            <p className="content-wrapper__paragraph">
              {info_item.description}
            </p>
            {content.map((item) => (
              <li>
                {item.subtitle &&
                  <div className="content-wrapper__subtitle">
                    {item.subtitle}
                  </div>
                }
                {item.list &&
                  <ul className="content-wrapper__sublist">
                    {item.list.map((list_item) => (
                      <li>
                        {list_item.item}
                      </li>
                    ))}
                  </ul>
                }
                {item.paragraph &&
                  <p className="content-wrapper__paragraph">
                    {item.paragraph}
                  </p>
                }
                {item.olList &&
                  <ul className="content-wrapper__sublistOl">
                    {item.olList.map((list_item) => (
                      <li>
                        {list_item.item}
                      </li>
                    ))}
                  </ul>
                }
              </li>
            ))}
          </ul>
        </div>
      </TemplateContainer>
    </HelmetContainer>
  );
};

export default InformationTabContent;