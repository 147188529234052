import { PartnerCardWrapper } from "../../partners/styledPartners";
import { NavLink } from "react-router-dom";

const PartnerCard = ({ image, url }) => {

  return (
    <NavLink to={"/partners/" + url}>
      <PartnerCardWrapper>
        <img src={image} alt="partner card image"/>
      </PartnerCardWrapper>
    </NavLink>
  );
};

export default PartnerCard;