import React from "react";
import HomePage from "./pages/home/HomePage";
import ExamplePage from "./pages/example/ExamplePage";
import InformationPage from "./pages/information/InformationPage";
import ForClientPage from "./pages/forClient/ForClientPage";
import FaqPage from "./pages/faq/FaqPage";
import UserConsentPage from "./pages/userConsent/UserConsentPage";
import PrivacyPolicyPage from "./pages/privacyPolicy/PrivacyPolicyPage";
import InformationDetails from "./pages/information/InformationDetails";
import PartnersPage from "./pages/partners/PartnersPage";
import PartnersDetails from "./pages/partners/PartnersDetails";
import WhatWeDoPage from "./pages/whatWeDo/WhatWeDoPage";
import AboutUs from "./pages/aboutUs/AboutUs";
import NotFoundPage from "./pages/not-found/NotFoundPage";

const routes = [
  {
    title: "Home page",
    path: "/",
    exact: true,
    component: HomePage
  },
  {
    title: "Example page",
    path: "/example-components",
    exact: false,
    component: ExamplePage
  },
  {
    title: "Information page",
    path: "/information",
    exact: true,
    component: InformationPage
  },
  {
    title: "Information details",
    path: "/information-details/:url",
    exact: false,
    component: InformationDetails
  },
  {
    title: "For client",
    path: "/for-client",
    exact: true,
    component: ForClientPage
  },
  {
    title: "FAQ",
    path: "/faq",
    exact: true,
    component: FaqPage
  },
  {
    title: "User consent",
    path: "/user-consent",
    exact: true,
    component: UserConsentPage
  },
  {
    title: "Privacy Policy",
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicyPage
  },
  {
    title: "Our partners",
    path: "/partners",
    exact: true,
    component: PartnersPage
  },
  {
    title: "Our partners",
    path: "/partners/:url",
    exact: false,
    component: PartnersDetails
  },
  {
    title: "What we do",
    path: "/what-we-do",
    exact: true,
    component: WhatWeDoPage
  },
  {
    title: "About us",
    path: "/about-us",
    exact: false,
    component: AboutUs
  },
  {
    title: "Page not found",
    path: "/page-not-found",
    exact: true,
    component: NotFoundPage
  }
];

export default routes;