import styled from "styled-components";

export const StyledSectionBackground = styled.div`
  width: 100%;
  ${({ height }) => height && `height: ${height}px`};
  ${({ background }) => background && `background-image: url("${background}")`};
  background-repeat: repeat-x;
  @media screen and (max-width: 992px) {
    height: ${({mob_height}) => mob_height }px;
    background-position-y: bottom;
    background-position-x: ${({pos}) => pos || "40"}%;
  }
`;