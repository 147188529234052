import React from "react";
import { Helmet } from "react-helmet-async";

import { StyledButton } from "../../styles/styledButton";
import { StyledMainTitle } from "../../styles/styledTitle";
import { StyledButtonWrapper } from "../styledExamples";

const ExampleButtons = () => {
  return (
    <div>
      <Helmet>
        <title>Example Buttons • ITLab-Studio</title>
      </Helmet>

      <StyledMainTitle size="18" mb="15">
        Example Buttons
      </StyledMainTitle>

      <StyledButtonWrapper>
        <StyledButton color="main">
          Main button
        </StyledButton>
        <StyledButton color="main" border>
          Main button (border)
        </StyledButton>
        <div>
          <StyledButton color="main" figure="circle">
            <span className="icon-star"/>
          </StyledButton>
        </div>
      </StyledButtonWrapper>

      <StyledButtonWrapper>
        <StyledButton color="neutral">
          Neutral button
        </StyledButton>
        <StyledButton color="neutral" border>
          Neutral button (border)
        </StyledButton>
        <div>
          <StyledButton color="neutral" figure="circle">
            <span className="icon-star"/>
          </StyledButton>
        </div>

      </StyledButtonWrapper>

      <StyledButtonWrapper>
        <StyledButton color="success">
          Success button
        </StyledButton>
        <StyledButton color="success" border>
          Success button (border)
        </StyledButton>
        <div>
          <StyledButton color="success" figure="circle">
            <span className="icon-star"/>
          </StyledButton>
        </div>
      </StyledButtonWrapper>

      <StyledButtonWrapper>
        <StyledButton color="danger">
          Danger button
        </StyledButton>
        <StyledButton color="danger" border>
          Danger button (border)
        </StyledButton>
        <div>
          <StyledButton color="danger" figure="circle">
            <span className="icon-star"/>
          </StyledButton>
        </div>
      </StyledButtonWrapper>

      <StyledButtonWrapper>
        <StyledButton color="warning">
          Warning button
        </StyledButton>
        <StyledButton color="warning" border>
          Warning button (border)
        </StyledButton>
        <div>
          <StyledButton color="warning" figure="circle">
            <span className="icon-star"/>
          </StyledButton>
        </div>
      </StyledButtonWrapper>

      <StyledButtonWrapper>
        <StyledButton color="info">
          Info button
        </StyledButton>
        <StyledButton color="info" border>
          Info button (border)
        </StyledButton>
        <div>
          <StyledButton color="info" figure="circle">
            <span className="icon-star"/>
          </StyledButton>
        </div>
      </StyledButtonWrapper>


    </div>
  );
};

export default ExampleButtons;