import React from "react";
import InformationCard from "../elements/informationCard/InformationCard";

import { StyledInformationContainer } from "./styledInformation";

const InformationContainer = ({t}) => {
  const info_items = t("info_items", {returnObjects: true});

  return (
    <StyledInformationContainer>
      {info_items.map((info_item) => (
        <InformationCard
          url={info_item.url}
          tag={info_item.tag}
          title={info_item.title}
          description={info_item.description}
          key={info_item.id}
          t={t}
        />
      ))}
    </StyledInformationContainer>
  );
};

export default InformationContainer;