import styled from "styled-components";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledFooterWrapper = styled.footer`
  padding: 45px 0 20px;
  background: ${({inverted}) => inverted?"#FFF":"#294965"};
  .footer-copyright {
    margin: 45px auto 0;
    color: rgba(41, 73, 101, 0.5);
    text-align: center;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 992px) {
    padding-top: 40px;
  }
`;

export const StyledFooterContainer = styled(StyledContainer)`
  color: ${({inverted}) => inverted?"#294965":"#FFF"};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .footer-nav {
    &-left,
    &-right {
      padding-top: 35px;
    }
    &-right {
      grid-template-columns: repeat(3, auto);
    }
    &__item {
      color: ${({inverted}) => inverted?"#294965":"#FFF"};
      font-size: 14px;
      font-weight: 700;
    }
    &__address {
      padding-top: 10px;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .footer-nav {
      &-left,
      &-right {
        padding-top: 20px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    .footer-nav {
      &-left,
      &-right {
        padding-top: 40px;
      }
      &-right {
        grid-template-columns: 100%;
        grid-gap: 30px;
        li {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;