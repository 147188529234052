import styled from "styled-components";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledHowItWorksWrapper = styled.div`
  padding: 45px 0;
`;

export const StyledHowItWorksContainer = styled(StyledContainer)`
  display: grid;
  justify-items: center;
  .steps-container {
    padding-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 345px));
    grid-template-areas: 'text1 image1 text2 image2' 
                         'image3 text3 image4 text4';
    grid-gap: 20px;
    justify-content: center;
    #text1 {
      grid-area: text1
    }
    #text2 {
      grid-area: text2
    }
    #text3 {
      grid-area: text3
    }
    #text4 {
      grid-area: text4
    }
    #image1 {
      grid-area: image1
    }
    #image2 {
      grid-area: image2
    }
    #image3 {
      grid-area: image3
    }
    #image4 {
      grid-area: image4
    }
    &__item {
      padding: 40px;
      max-width: 345px;
      width: 100%;
      height: 345px;
      color: #FFFFFF;
      font-size: 14px;
      position: relative;
      border-radius: 10px;
      background: #FFFFFF;
      border: 1px solid rgba(41, 73, 101, 0.2);
      box-shadow: 0 27px 71px rgba(41, 73, 101, 0.1);
      &-text {
        padding: 30px;
        background: #294965;
        box-shadow: none;
        &:after {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          top: 40px;
          right: -32px;
          border-top: 42px solid transparent;
          border-bottom: 42px solid transparent;
          border-left: 50px solid #294965;
          z-index: 1;
        }
      }
      .rounded-image-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        .rounded-image {
          width: 100%;
        }
      }
    }
    &__counter {
      width: fit-content;
      font-size: 64px;
      line-height: 80px;
      font-weight: 600;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      &:before {
        content: "";
        width: 60px;
        height: 7px;
        display: inline-block;
        background: linear-gradient(173.28deg, #AFAFAF 30.45%, #DEDEDE 59.88%, #C3C3C3 69.08%, #BDBBBB 85.48%);
        border-radius: 5px;
      }
    }
    &__title {
      margin: 25px 0;
      color: #FDDF1F;
      font-weight: 600;
      font-size: 24px;
    }
    &__content {
      line-height: 140%;
    }

    #text4:after {
      border: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .steps-container {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: none;
      #text1,
      #text2,
      #text3,
      #text4,
      #image1,
      #image2,
      #image3,
      #image4 {
        grid-area: auto
      }
      &__item {
        max-width: 100%;
        .rounded-image {
          max-width: 270px;
          margin: 0 auto;
        }
      }

      #text4:after {
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 32px solid #294965;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .steps-container {
      &__item {
        font-size: 16px;
      }
      &__title {
        font-size: 22px;
      }
    }
  }


  @media screen and (max-width: 578px) {
    .steps-container {
      &__item {
        width: auto;
        height: auto;
        padding: 10px;
        font-size: 12px;
        .rounded-image-wrapper {
          padding: 10px;
        }
      }
      &__item, #text4 {
        &:after {
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 25px solid #294965;
          top: 10px;
          right: -24px;
        }
      }

      &__title {
        margin: 6px 0;
        font-size: 14px;
      }

      &__counter {
        font-size: 18px;
        line-height: 22px;

        &:before {
          width: 35px;
          height: 5px;
        }
      }
    }
  }
`;