import styled from "styled-components";

const side = {
  side: ''
}

const changeSide = (side) => {
  switch (side) {
    case 'left' :
      return `
        grid-column: 1;
      `;
    case 'right' :
      return `
        grid-column: 2;
      `;
    default :
      return `
        grid-column: 1;
      `;
  }
}


export const StyledSectionContent = styled('div', side)`
  padding-left: 40px;
  ${({side}) => changeSide(side)}
  position: relative;
  .section-content {
    margin-bottom: 50px;
    color: ${({ inverted }) => inverted ? "#FFFFFF" : "#294965"};
    font-size: 18px;
    line-height: 140%;
    b {
      color: ${({ boldColor }) => boldColor || "inherit"};
      font-weight: 600;
    }
    p:not(:last-child) {
      margin-bottom: 36px;
    }
  }
  
  @media screen and (max-width: 992px) {
    padding-left: 0;
    font-size: 16px;
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-column: 1;
    .section-content {
      margin-bottom: 30px;
      width: auto;
      font-size: 16px;
    }
  }
`;