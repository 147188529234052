import { StyledSectionBackground } from "./styledSectionBackground";

const SectionBackground = ({background, height, pos, mob_height}) => {
  return(
    <StyledSectionBackground
      background={background}
      height={height}
      pos={pos}
      mob_height={mob_height}
    />
  )
}

export default SectionBackground;