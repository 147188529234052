import React, { useEffect, useState } from "react";
import CustomTitle from "../../elements/title/CustomTitle";
import RoundedImage from "../../elements/roundedImage/RoundedImage";
import InputGroup from "../../elements/inputGroup/InputGroup";
import { useTranslation } from "react-i18next";
import { regex } from "../../../utils/consts";
import ReCAPTCHA from "react-google-recaptcha";
import ConfirmationModal from "../../elements/modal/ConfirmationModal";
import FragmentSpinner from "../../elements/spinner/FragmentSpinner";
import { validateValue } from "../../../utils/validator";

import image from "../../../assets/images/letsWorkTogether/letsWorkTogetherImage.png";

import { StyledButton } from "../../styles/styledButton";
import { StyledSectionContent } from "../../styles/styledSectionContent";
import { StyledLetsWorkTogetherContainer, StyledLetsWorkTogetherWrapper } from "./styledLetsWorkTogether";

const LetsWorkTogetherSection = () => {
  const { t } = useTranslation("home");
  const messageLength = 10;
  const [captchaToken, setCaptchaToken] = useState();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [{ name, phone, email, country, message }, setFormDetails] =
    useState({ name: "", phone: "", email: "", country: "", message: "" });
  const [errors, setErrors] = useState(null);

  const recaptchaRef = React.createRef();

  const formDecorator = (send, event) => {
    return function (event) {
      event.preventDefault();

      if (errors["name"] || errors["email"] || errors["phone"] || errors["country"] || errors["message"]) {
        return false;
      }
      else{
        send.apply();
      }
    };
  };


  const sendEmail = () => {
    setLoading(true);

    const url = window.location.origin + "/contact.php";

    const data =
      "name=" + encodeURIComponent(name) +
      "&email=" + encodeURIComponent(email) +
      "&phoneNumber=" + encodeURIComponent(phone) +
      "&comment=" + encodeURIComponent(message) +
      "&country=" + encodeURIComponent(country) +
      "&recaptcha_response=" + encodeURIComponent(captchaToken);

    try {
      const response = fetch(url, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST",
        body: data
      }).then(response => {
        if (response.status === 200) {
          setFormDetails({ name: "", phone: "", email: "", country: "", message: "" });
          setVisible(true);
          setLoading(false);
        } else {
          console.log("Message hasn't sent");
          setLoading(false);
        }
      });
    } catch (error) {
      console.log("Message hasn't sent");
      setLoading(false);
    }
  };

  const onChangeInput = (event) => {
    const { name, attributes, value } = event.target;

    if (name === "message") {
      setFormDetails((prevState) => ({
        ...prevState,
        [name]: value
      }));

      const valueWithoutSpacebars = value.replace(/ +/g, " ").trim();

      if (valueWithoutSpacebars.length < messageLength) {
        setErrors({ ...errors, message: `Минимальное количество символов в сообщении - ${messageLength}` });
        return null;
      }

      setErrors({ ...errors, message: null });
      return null;
    }

    let regexData = attributes.getNamedItem("data-regex").value;

    setErrors((prevState) => ({
      ...prevState,
      [name]: null
    }));

    const regexMap = new Map([
      ["name", regex.NAME],
      ["country", regex.COUNTRY],
      ["email", regex.EMAIL]
    ]);

    regexData = regexMap.get(name);

    if (name === "phone") {
      if (isNaN(value)) {
        setErrors({ ...errors, phone: t("validation_default") });
      }
      else if (value.length < 9) {
        setErrors({ ...errors, phone: t("validation_phone") });
      }
    }

    if (!validateValue(value, regexData)) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: t("validation_"+name)
      }));
    }
    setFormDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = formDecorator(sendEmail);

  useEffect(() => {
    recaptchaRef.current.execute();
  }, []);

  return (
    <>
      <StyledLetsWorkTogetherWrapper id="contacts">
        <StyledLetsWorkTogetherContainer wrapper="content">
          <RoundedImage
            image={image}
            size="715"
          />
          <CustomTitle
            title={t("lets_work_together_title")}
            color="#FDDF1F"
          />
          <ConfirmationModal
            visible={visible}
            setVisible={setVisible}
            title={t("modal_title")}
            message={t("modal_message")}
          />
          <StyledSectionContent side="right">
            {loading && <FragmentSpinner background="transparent" position="absolute"/>}
            <form
              action=""
              onSubmit={handleSubmit}
            >
              <InputGroup
                disabled={loading}
                id="name"
                type="text"
                name="name"
                data-regex={regex.NAME}
                autoComplete="off"
                onChange={onChangeInput}
                required
                error={errors?.["name"]}
                value={name}
                placeholder={t("name_placeholder")}
              />
              <InputGroup
                disabled={loading}
                id="phone"
                type="text"
                name="phone"
                data-regex={regex.PHONE}
                autoComplete="off"
                onChange={onChangeInput}
                required
                error={errors?.["phone"]}
                value={phone}
                placeholder={t("phone_placeholder")}
              />
              <InputGroup
                disabled={loading}
                id="country"
                type="text"
                name="country"
                data-regex={regex.COUNTRY}
                autoComplete="off"
                onChange={onChangeInput}
                required
                error={errors?.["country"]}
                value={country}
                placeholder={t("country_placeholder")}
              />
              <InputGroup
                disabled={loading}
                id="email"
                type="email"
                name="email"
                data-regex={regex.EMAIL}
                autoComplete="off"
                onChange={onChangeInput}
                required
                error={errors?.["email"]}
                value={email}
                placeholder={t("email_placeholder")}
              />
              <InputGroup
                disabled={loading}
                id="message"
                name="message"
                type="text"
                data-regex={regex.TEXT}
                autoComplete="off"
                onChange={onChangeInput}
                required
                error={errors?.["message"]}
                value={message}
                placeholder={t("message_placeholder")}
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC}
                onChange={(value) => {
                  setCaptchaToken(value);
                }}
              />
              <div className="form-actions">
                <StyledButton
                  disabled={loading}
                  tybe="submit"
                  color="main"
                >
                  {t("contact_btn")}
                </StyledButton>
              </div>
            </form>
          </StyledSectionContent>
        </StyledLetsWorkTogetherContainer>
      </StyledLetsWorkTogetherWrapper>
    </>
  );
};

export default LetsWorkTogetherSection;