import React from "react";
import Footer from "../../components/elements/footer/Footer";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import CustomTitle from "../../components/elements/title/CustomTitle";
import BreadcrumbContainer from "../../components/elements/breadcrumbs/BreadcrumbContainer";
import UserConsentContainer from "../../components/userConsent/UserConsentContainer";

import backgroundTop from "../../assets/images/backgrounds/background1.svg";

import { StyledContainer } from "../../components/styles/styledContainer";
import { Helmet } from "react-helmet-async";
import HelmetContainer from "../../components/elements/helmetContainer/HelmetContainer";
import { useTranslation } from "react-i18next";

const UserConsentPage = () => {
  const { t } = useTranslation("userConsent");

  return (
    <HelmetContainer title={t("meta_title")} description={t("meta_description")}>
      <SectionBackground background={backgroundTop} height="180"/>
      <StyledContainer>
        <CustomTitle align="center" valign="center" title={t("title")} as="h1"/>
        <BreadcrumbContainer current={t("title")}/>
        <UserConsentContainer/>
      </StyledContainer>
      <Footer/>
    </HelmetContainer>
  );
};

export default UserConsentPage;