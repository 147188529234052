import React from "react";
import CustomTitle from "../../components/elements/title/CustomTitle";
import ForClientContainer from "../../components/forClient/ForClientContainer";
import BreadcrumbContainer from "../../components/elements/breadcrumbs/BreadcrumbContainer";
import Footer from "../../components/elements/footer/Footer";
import { useTranslation } from "react-i18next";

import { StyledForClientWrapper } from "../../components/forClient/styledForClient";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import backgroundTop from "../../assets/images/backgrounds/background1.svg";
import HelmetContainer from "../../components/elements/helmetContainer/HelmetContainer";

const ForClientPage = () => {
  const { t } = useTranslation("forClient")
  return (
    <HelmetContainer title={t("meta_title")} description={t("meta_description")}>
      <SectionBackground background={backgroundTop} height="180"/>
      <StyledForClientWrapper>
        <CustomTitle align="center" title={t("title")} mb="55" as="h1"/>
        <BreadcrumbContainer current={t("title")}/>
        <ForClientContainer t={t}/>
      </StyledForClientWrapper>
      <Footer/>
    </HelmetContainer>
);
};

export default ForClientPage;
