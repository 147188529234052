import styled from "styled-components"

export const InformationCardWrapper = styled.div`
  .information-item{
    height: 485px;
    background: #F2F1F1;
    box-shadow: 0 27px 71px rgba(41, 73, 101, 0.1);
    border-radius: 10px;
    &__header{
      height: 124px;
      padding: 30px;
      color: #FFFFFF;
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      background: #294965;
      border-radius: 10px 10px 0 0;
      display: grid;
      grid-template-columns: 26px 1fr;
      &::before{
        content: "";
        width: 10px;
        height: 10px;
        margin: 8px;
        display: block;
        background: #FDDD10;
        border-radius: 50%;
      }
    }
    &__type{
      color: #FDDD10;
    }
    &__title{
      height: 64px;
      display: -webkit-box;
      margin: 0 auto;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__body{
      height: 440px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__content{
      height: 265px;
      padding: 24px 50px;
      color: #294965;
      display: block;
      display: -webkit-box;
      margin: 0 auto;
      font-size: 18px;
      line-height: 24px;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: justify;
    }
    &__button{
      margin: 20px 0;
    }
    &:hover{
      transition: 0.3s;
      background: #294965;
      .information-item__content{
        color: #FFFFFF;
      }
    }
    @media screen and (max-width: 992px){
      height: 100%;
      &__header{
          padding: 15px 40px;
          font-size: 16px;
          line-height: 24px;
          grid-template-columns: 22px 1fr;
          &::before{
            width: 8px;
            height: 8px;
            margin: 7px;
          }
        }
        &__body{
          height: 285px;
          margin-bottom: 15px;
        }
        &__content{
          -webkit-line-clamp: 10;
          height: 100%;
          padding: 15px 30px;
          font-size: 14px;
          line-height: 18px;
        }
    }
  }
`;