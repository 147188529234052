import React from "react";

const CustomSlide = ({ user, rate, title, content }) => {

  const rated = (rate) => {
    let rating = []
    for (let i = 0; i < rate; i++) rating.push(<span className="icon-star-filled"/>);
    for (let i = 0; i < 5 - rate; i++) rating.push(<span className="icon-star"/>);
    return rating;
  };

  return (
    <div className="review">
      <div className="review__user-img">
        {user[0]}
      </div>
      <div className="review__description">
        <div className="review__author">
          {user}
          <div className="review__rate" id="rate">
            {rated(rate)}
          </div>
        </div>
        <div className="review__title">
          {title}
        </div>
        <div className="review__content">
          {content}
        </div>
      </div>
    </div>
  );
};

export default CustomSlide;