import React  from "react";
import { TemplateContainer } from "../styles/styledContainer";
import RoundedImage from "../elements/roundedImage/RoundedImage";

import image from "../../assets/images/userConsent/image.svg";

const UserConsentContainer = () => {

  return (
    <TemplateContainer>
      <div className="aside-image">
        <RoundedImage image={image} size="230" pd="38"/>
      </div>
      <div className="content-wrapper">
        <div className="content-wrapper__title">
          EU user consent policy
        </div>
        <p className="content-wrapper__paragraph">
          If your agreement with Google incorporates this policy, or you otherwise use a Google product that incorporates
          this policy, you must ensure that certain disclosures are given to, and consents obtained from, end users in the
          European Economic Area along with the UK. If you fail to comply with this policy, we may limit or suspend your
          use of the Google product and/or terminate your agreement.
        </p>
        <ul className="content-wrapper__list">
          <li>
            <div className="content-wrapper__subtitle">
              Properties under your control
            </div>
            <p className="content-wrapper__paragraph">
              For Google products used on any site, app or other property that is under your control, or that of your
              affiliate or your client, the following duties apply for end users in the European Economic Area along with
              the UK.
            </p>
          </li>
          <li>
            <div className="content-wrapper__subtitle">
              You must obtain end users’ legally valid consent to:
            </div>
            <ul className="content-wrapper__sublist">
              <li>
                <p className="content-wrapper__paragraph">
                  the use of cookies or other local storage where legally required;
                </p>
              </li>
              <li>
                <p className="content-wrapper__paragraph">
                  and the collection, sharing, and use of personal data for personalization of ads.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <div className="content-wrapper__subtitle">
              When seeking consent you must:
            </div>
            <ul className="content-wrapper__sublist">
              <li>
                <p className="content-wrapper__paragraph">
                  retain records of consent given by end users;
                </p>
              </li>
              <li>
                <p className="content-wrapper__paragraph">
                  and provide end users with clear instructions for revocation of consent.
                </p>
              </li>
            </ul>
            <p className="content-wrapper__paragraph">
              You must clearly identify each party that may collect, receive, or use end users’ personal data as a
              consequence of your use of a Google product. You must also provide end users with prominent and easily
              accessible information about that party’s use of end users’ personal data.
            </p>
          </li>
          <li>
            <div className="content-wrapper__subtitle">
              Properties under your control
            </div>
            <p className="content-wrapper__paragraph">
              For Google products used on any site, app or other property that is under your control, or that of your
              affiliate or your client, the following duties apply for end users in the European Economic Area along with
              the UK.
            </p>
          </li>
        </ul>
      </div>
    </TemplateContainer>
  );
};

export default UserConsentContainer;