import React from "react";
import CustomTitle from "../../elements/title/CustomTitle";
import RoundedImage from "../../elements/roundedImage/RoundedImage";
import { Trans, useTranslation } from "react-i18next";

import aboutImage from "../../../assets/images/about/aboutImage.svg";

import { StyledButton } from "../../styles/styledButton";
import { StyledSectionContent } from "../../styles/styledSectionContent";
import { StyledAboutContainer, StyledAboutWrapper } from "./styledAbout";
import { NavLink } from "react-router-dom";

const AboutSection = () => {
  const { t } = useTranslation("home")

  return (
    <StyledAboutWrapper id="about_us">
      <StyledAboutContainer wrapper="content">
        <RoundedImage image={aboutImage} size="715" />
        <CustomTitle title={t("about_title")}/>
        <StyledSectionContent side="right">
          <div className="section-content">
            <p>
              <Trans components={{NavLink: <NavLink />}}>
                {t("about_paragraph")}
              </Trans>
            </p>
          </div>
          <StyledButton as="a" href="#contacts" color="main">
            {t("contact_btn")}
          </StyledButton>
        </StyledSectionContent>
      </StyledAboutContainer>
    </StyledAboutWrapper>
  );
};

export default AboutSection;