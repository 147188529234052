import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";

const HelmetContainer = ({ title, description, children, page }) => {

  return (
    <>
      <Helmet>
        <title>{`${title} - Home4crypto.io`}</title>
        {description && <meta
          name="description"
          content={description}
        />}
        <link
          rel="canonical"
          href={window.location.href}
        />
      </Helmet>
      {children}
    </>
  );
};

export default HelmetContainer;