import styled from "styled-components"

export const StyledPageContent = styled.div`
  max-width: 1250px;
  margin: 85px auto 20px;
  font-weight: 500;
  font-size: 18px;
  color: #294965;
  line-height: 25px;
  grid-column-start: 1;
  grid-column-end: 3;
  .page-content{
    &__paragraph{
      margin-bottom: 32px;
    }
  }
  @media screen and (max-width: 992px){
    grid-column: 1;
    margin: 15px auto 20px;
    font-size: 16px;
  }
`;