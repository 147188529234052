import styled from "styled-components";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledWhatWeDoWrapper = styled.div`
  padding: 80px 0 40px;
  position: relative;
  background: #294965;
  @media screen and (max-width: 992px) {
    padding: 40px 0 0;
  }
`;

export const StyledWhatWeDoContainer = styled(StyledContainer)`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 70px minmax(max-content, 390px);
  .video {
    max-width: 755px;
    &__preview {
      padding-top: 65%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-position: top;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-gap: 30px;
    .video {
      max-width: 400px;
      margin: 0 auto;
    }
  }
`;