import React, { useState, useEffect } from "react";
import RoundedImage from "../elements/roundedImage/RoundedImage";
import ConfirmationModal from "../elements/modal/ConfirmationModal";
import Select, { Option } from "rc-select";
import Slider from 'rc-slider';
import { regex } from "../../utils/consts";
import ReCAPTCHA from "react-google-recaptcha";
import { validateValue } from "../../utils/validator";
import FragmentSpinner from "../elements/spinner/FragmentSpinner";

import image from "../../assets/images/forClient/image.svg";
import 'rc-slider/assets/index.css';

import { CustomInput, StyledForClientContainer } from "./styledForClient";
import { StyledSelect } from "../styles/styledSelect";
import { StyledPageContent } from "../styles/styledPageContent";
import { StyledRange } from "../styles/styledRange";
import { StyledButton } from "../styles/styledButton";

const ForClientContainer = ({ t }) => {
  const [selectValue, setSelectValue] = useState();
  const [visible, setVisible] = useState(false);
  const points = t("points", {returnObjects: true});
  const [rangeVal, setRangeVal] = useState(0)

  const [captchaToken, setCaptchaToken] = useState();
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [{ name, email, country, city, property, currency, bedrooms, budget }, setFormDetails] =
    useState({ name: "", email: "", country: null, city: null, property: null, currency: null, bedrooms: null, budget: null });

  const recaptchaRef = React.createRef();

  const formDecorator = (send, event) => {
    return function (event) {
      event.preventDefault();

      if (errors["name"] || errors["email"] || errors["phone"] || errors["country"] || errors["message"]) {
        return false;
      }
      else{
        send.apply();
      }
    };
  };

  const sendEmail = () => {
    setLoading(true);

    let recaptchaValue = recaptchaRef.current.getValue();

    const url = window.location.origin + "/contactForClient.php";

    const data =
      "name=" + encodeURIComponent(name) +
      "&email=" + encodeURIComponent(email) +
      "&country=" + encodeURIComponent(country) +
      "&city=" + encodeURIComponent(city) +
      "&property=" + encodeURIComponent(property) +
      "&currency=" + encodeURIComponent(currency) +
      "&bedrooms=" + encodeURIComponent(bedrooms) +
      "&budget=" + encodeURIComponent(budget) +
      "&recaptcha_response=" + encodeURIComponent(recaptchaValue);

    try {
      const response = fetch(url, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST",
        body: data
      }).then(response => {
        if (response.status === 200) {
          setFormDetails({ name: "", email: "", country: null, city: null, property: null, currency: null, bedrooms: null, budget: null });
          setVisible(true);
          setLoading(false);
        } else {
          console.log("Message hasn't sent");
          setLoading(false);
        }
      });
    } catch (error) {
      console.log("Message hasn't sent");
      setLoading(false);
    }
  };

  const onRangeInput = (value) => {
    setRangeVal(value)
    setFormDetails((prevState) => ({
      ...prevState,
      ["budget"]: value
    }));
  }

  const onChangeInput = (event) => {
    const { name, attributes, value } = event.target;

    let regexData = attributes.getNamedItem("data-regex").value;

    setErrors((prevState) => ({
      ...prevState,
      [name]: null
    }));

    const regexMap = new Map([
      ["name", regex.NAME],
      ["email", regex.EMAIL]
    ]);

    regexData = regexMap.get(name);

    if (!validateValue(value, regexData)) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: t("validation_"+name)
      }));
    }

    setFormDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeSelect = (value, name) => {
    setSelectValue(value)
    setFormDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = formDecorator(sendEmail);

  useEffect(() => {
    recaptchaRef.current.execute();
  }, []);


  return (
    <>
      <ConfirmationModal
        visible={visible}
        setVisible={setVisible}
        title={t("modal_title")}
        message={t("modal_message")}
      />
      <StyledForClientContainer wrapper="content">
        <StyledPageContent>
          <div className="page-content">
            {points.map((point) => (
              <p key={point.paragraph} className="page-content__paragraph">
                {point.paragraph}
              </p>
            ))}
          </div>
        </StyledPageContent>
        <RoundedImage image={image}/>
        <form
          className="for-client__form"
          action=""
          onSubmit={handleSubmit}>
          {loading && <FragmentSpinner position="absolute"/>}
          <CustomInput
            disabled={loading}
            id="name"
            type="text"
            name="name"
            data-regex={regex.NAME}
            autoComplete="off"
            onChange={onChangeInput}
            required
            error={errors?.["name"]}
            value={name}
            placeholder={t("name_placeholder")}
          />
          <CustomInput
            disabled={loading}
            id="email"
            type="email"
            name="email"
            data-regex={regex.EMAIL}
            autoComplete="off"
            onChange={onChangeInput}
            required
            error={errors?.["email"]}
            value={email}
            placeholder={t("email_placeholder")}
          />
          <StyledSelect mb="20">
            <Select
              disabled={loading}
              id="country"
              name="country"
              value={country}
              className="custom-select"
              placeholder={t("country_placeholder")}
              onChange={(value) => onChangeSelect(value, "country")}
            >
              <Option value="cyprus">
                <div className="option-select-item">
                  Cyprus
                </div>
              </Option>
            </Select>
          </StyledSelect>
          <StyledSelect mb="20">
            <Select
              disabled={loading}
              id="city"
              name="city"
              value={city}
              className="custom-select"
              placeholder={t("city_placeholder")}
              defaultValue={null}
              onChange={(value) => onChangeSelect(value, "city")}
            >
              <Option value="nicosia">
                <div className="option-select-item">
                  Nicosia
                </div>
              </Option>
              <Option value="paphos">
                <div className="option-select-item">
                  Paphos
                </div>
              </Option>
              <Option value="limassol">
                <div className="option-select-item">
                  Limassol
                </div>
              </Option>
              <Option value="larnaca">
                <div className="option-select-item">
                  Larnaca
                </div>
              </Option>
            </Select>
          </StyledSelect>
          <StyledSelect mb="20">
            <Select
              disabled={loading}
              id="property"
              name="property"
              value={property}
              className="custom-select"
              defaultValue={null}
              onChange={(value) => onChangeSelect(value, "property")}
              placeholder={t("property_placeholder")}
            >
              <Option value="property1">
                <div className="option-select-item">
                  Property 1
                </div>
              </Option>
            </Select>
          </StyledSelect>
          <StyledSelect mb="20">
            <Select
              disabled={loading}
              id="currency"
              name="currency"
              value={currency}
              className="custom-select"
              placeholder={t("currency_placeholder")}
              defaultValue={null}
              onChange={(value) => onChangeSelect(value, "currency")}
            >
              <Option value="BTC">
                <div className="option-select-item">
                  BTC
                </div>
              </Option>
              <Option value="ETH">
                <div className="option-select-item">
                  ETH
                </div>
              </Option>
              <Option value="TRX">
                <div className="option-select-item">
                  TRX
                </div>
              </Option>
              <Option value="USDT">
                <div className="option-select-item">
                  USDT
                </div>
              </Option>
            </Select>
          </StyledSelect>
          <StyledSelect mb="20">
            <Select
              disabled={loading}
              id="bedrooms"
              name="bedrooms"
              className="custom-select"
              defaultValue={null}
              placeholder={t("bedrooms_placeholder")}
              onChange={(value) => onChangeSelect(value, "bedrooms")}
            >
              <Option value="2">
                <div className="option-select-item">
                  Two-bedroom
                </div>
              </Option>
            </Select>
          </StyledSelect>
          <StyledRange>
            <label htmlFor="budget">
              {t("budget_placeholder")}
              <div className="range-value">{rangeVal} €</div>
            </label>
            <div className="range">
              <div className="range__min">Min.</div>
              <Slider
                disabled={loading}
                name="budget"
                min={0}
                max={100000}
                step={1000}
                onChange={value => onRangeInput(value)}
              />
              <div className="range__max">
                Max.</div>
            </div>
          </StyledRange>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC}
            onChange={(value) => {
              setCaptchaToken(value);
            }}
          />
          <StyledButton
            disabled={loading}
            color="main"
            type="submit"
          >
            {t("search_btn")}
          </StyledButton>
        </form>
      </StyledForClientContainer>
    </>
  );
};

export default ForClientContainer;