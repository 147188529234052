import React  from "react";
import { TemplateContainer } from "../styles/styledContainer";
import RoundedImage from "../elements/roundedImage/RoundedImage";

import image from "../../assets/images/faq/image.svg";

const FaqContainer = ({t}) => {
  const points = t("points", {returnObjects: true});

  return (
    <TemplateContainer>
      <div className="aside-image">
        <RoundedImage image={image} size="230" pd="38"/>
      </div>
      <div className="content-wrapper">
        <div className="content-wrapper__title">
          {t("header")}
        </div>
        <p className="content-wrapper__paragraph">
          {t("paragraph")}
        </p>
        <ul className="content-wrapper__list">
          {points.map((point) => (
            <li>
              <div className="content-wrapper__subtitle">
                {point.subtitle}
              </div>
              <p className="content-wrapper__paragraph">
                {point.content}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </TemplateContainer>
  );
};

export default FaqContainer;