import React from "react";
import Footer from "../../components/elements/footer/Footer";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import CustomTitle from "../../components/elements/title/CustomTitle";
import BreadcrumbContainer from "../../components/elements/breadcrumbs/BreadcrumbContainer";
import FaqContainer from "../../components/faq/FaqContainer";

import backgroundTop from "../../assets/images/backgrounds/background1.svg";

import { StyledContainer } from "../../components/styles/styledContainer";
import HelmetContainer from "../../components/elements/helmetContainer/HelmetContainer";
import { useTranslation } from "react-i18next";

const FaqPage = () => {

  const { t } = useTranslation("faq");

  return (
    <HelmetContainer title={t("meta_title")} description={t("meta_description")}>
      <SectionBackground
        background={backgroundTop}
        height="180"
      />
      <StyledContainer>
        <CustomTitle
          align="center"
          valign="center"
          title="FAQ"
          as="h1"
        />
        <BreadcrumbContainer current={t("title")} />
        <FaqContainer t={t} />
      </StyledContainer>
      <Footer />
    </HelmetContainer>
  );
};

export default FaqPage;