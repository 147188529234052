import styled from "styled-components";

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: ${({ align }) => align || "normal"};
  ${({mt}) => mt && `margin-top: ${mt}px`};
  ${({mb}) => mb && `margin-bottom: ${mb}px`};
  @media screen and (max-width: 992px) {
    justify-content: center;
    grid-row: 1 / auto;
  }
`;

export const StyledTitleText = styled.div`
  min-height: 70px;
  padding-left: 45px;
  color: ${({ color }) => color || "#294965"};
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  display: inline-flex;
  align-items: center;
  position: relative;
  .category{
    margin-right: 18px;
    color: #FDDA2E;
  }
  &:before {
    content: "";
    width: 7px;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(173.28deg, #AFAFAF 30.45%, #DEDEDE 59.88%, #C3C3C3 69.08%, #BDBBBB 85.48%);
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 992px) {
    font-size: 26px;
    line-height: 40px;
  }
  
  @media screen and (max-width: 768px) {
    min-height: 40px;
    padding-left: 20px;
    font-size: 22px;
    line-height: 22px;
    &:before {
      width: 5px;
    }
  }
  
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;