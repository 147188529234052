import styled from "styled-components";

const size = {
  size: ''
}

const changeContainerSize = (size) => {
  switch (size) {
    case 'xl':
      return `
        max-width: 1920px
      `;
    default:
      return `
        max-width: 1470px
      `;
  }
};

export const StyledContentWrapper = styled.div`
  background-color: #FFFFFF;
`;

export const StyledContainer = styled("div", size)`
  ${({ size }) => changeContainerSize(size)};
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  ${({ wrapper }) => wrapper !== "content" && `min-height: calc( 100vh - 262px - 265px )`};
  ${({ wrapper }) => wrapper !== "content" && `padding-bottom: 30px`};
  @media screen and (max-width: 992px) {
    ${({ wrapper }) => wrapper !== "content" && `height: auto`};
    ${({ wrapper }) => wrapper !== "content" && `padding-bottom: 50px`};
  }
`;

export const TemplateContainer = styled.div`
  padding: 45px;
  display: grid;
  grid-template-columns: ${({ grid }) => grid === "tab" ? `1fr` : `300px 1fr`};
  grid-gap: 30px;
  border-radius: 10px;
  a{
    text-decoration: underline;
  }

  .aside-image {
    display: grid;
    grid-template-columns: 1fr 7px;
    &:after {
      content: "";
      width: 7px;
      height: 230px;
      background: linear-gradient(173.28deg, #AFAFAF 30.45%, #DEDEDE 59.88%, #C3C3C3 69.08%, #BDBBBB 85.48%);
      border-radius: 5px;
    }
  }
  .content-wrapper{
    max-width: 800px;
    color: #294965;
    font-size: 18px;
    font-weight: 400;
    &__title{
      padding-bottom: 20px;
      font-size: 26px;
      font-weight: 700;
      line-height: 38px;
    }
    &__paragraph{
      text-align: justify;
    }
    &__list{
      padding: 18px 0;
      li {
        //display: grid;
        //grid-template-columns: 15px 1fr;

        &:before {
          margin: 6px 0;
          content: "";
          width: 10px;
          height: 10px;
          background: #FDDD10;
          border-radius: 50%;
        }
      }
    }
    &__subtitle{
      padding: 18px 0;
      font-weight: 700;
      &:before{
        content: "";
        width: 10px;
        height: 10px;
        margin-right: 10px;
        display: inline-block;
        background: #FDDD10;
        border-radius: 50%;
      }
    }
    &__sublist{
      margin-left: 18px;
      list-style: disc;
      &:last-child{
        padding-bottom: 18px;
      }
    }
    &__sublistOl{
      padding: 18px 0 18px 20px;
      list-style-type: decimal;
    }
  }
  
  @media screen and (max-width: 768px){
    margin-top: 10px;
    grid-template-columns: 1fr;
    .aside-image{
      grid-template-columns: 1fr;
      &:after{
        display: none;
      }
    }
    .content-wrapper{
      font-size: 12px;
      &__title{
        font-size: 14px;
        &:before{
          content: "";
          width: 10px;
          height: 10px;
          margin-right: 10px;
          display: inline-block;
          background: #FDDD10;
          border-radius: 50%;
        }
      }
      &__subtitle{
        font-size: 14px;
        &:before{
          display: none;
        }
      }
    }
  }
`;