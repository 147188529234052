import React from "react";
import { StyledAboutUsContainer, StyledAboutUsInfoBlock } from "./styledAboutUs";
import { useTranslation } from "react-i18next";


const AboutUsContainer = () => {
  const { t } = useTranslation("aboutUs");
  const info_aboutUS = t("info_aboutUS", {returnObjects: true});
  const content = info_aboutUS.content;

  return (
    <StyledAboutUsContainer>
      <StyledAboutUsInfoBlock grid="tab">
        <div className="content-wrapper">
          <h1 className="content-wrapper__title">
            {info_aboutUS.title}
          </h1>
          <ul className="content-wrapper__content">
            <p className="content-wrapper__paragraph">
              {info_aboutUS.description}
            </p>
            {content.map((item) => (
              <li>
                {item.subtitle &&
                  <div className="content-wrapper__subtitle">
                    {item.subtitle}
                  </div>
                }
                {item.list &&
                  <ul className="content-wrapper__sublist">
                    {item.list.map((list_item) => (
                      <li>
                        {list_item.item}
                      </li>
                    ))}
                  </ul>
                }
                {item.paragraph &&
                    <p className="content-wrapper__paragraph">
                      {item.paragraph}
                    </p>

                }
                {item.olList &&
                  <ul className="content-wrapper__sublistOl">
                    {item.olList.map((list_item) => (
                      <li>
                        {list_item.item}
                      </li>
                    ))}
                  </ul>
                }
              </li>
            ))}
          </ul>
        </div>
      </StyledAboutUsInfoBlock>
    </StyledAboutUsContainer>
  )
}

export default AboutUsContainer