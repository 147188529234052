import React from "react";
import { Grid, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomTitle from "../../elements/title/CustomTitle";
import CustomSlide from "../../elements/customSlide/CustomSlide";
import { useTranslation } from "react-i18next";

import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";

import { StyledCustomerExperienceContainer, StyledCustomerExperienceWrapper } from "./styledCustomerExperience";

const CustomerExperienceSection = ({pd}) => {
  const { t } = useTranslation("home")
  const reviews = t("reviews", {returnObjects: true});

  const responsive = {
    0: {
      slidesPerView: 1,
      spaceBetween: 15
    },
    768: {
      slidesPerView: 2,
      centeredSlides: false
    },
    1200: {
      slidesPerView: 3,
      centeredSlides: true,
      spaceBetween: 30
    }
  };

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <StyledCustomerExperienceWrapper id="reviews" pd={pd}>
      <StyledCustomerExperienceContainer wrapper="content">
        <CustomTitle align="center" title={t("reviews_title")}/>
        <div className="slider-wrapper">
          <Swiper
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
            modules={[Navigation, Grid]}
            className="mySwiper"
            breakpoints={responsive}
          >
            {reviews.map((review) => (
              <SwiperSlide key={"review"+review.author}>
                <CustomSlide
                  user={review.author}
                  // rate="5"
                  // title="My best deal ever!"
                  key={"slide"+review.author}
                  content={review.content}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <button className="swiper-navigation-prev icon-angle-small-left" ref={navigationPrevRef}/>
          <button className="swiper-navigation-next icon-angle-small-right" ref={navigationNextRef}/>
        </div>
      </StyledCustomerExperienceContainer>
    </StyledCustomerExperienceWrapper>
  );
};

export default CustomerExperienceSection;