import styled from "styled-components";

export const StyledRange = styled.div`
  padding: 21px 32px;
  color: #294965;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  .range{
    display: flex;
    align-items: center;
    &-value{
      text-align: end;
    }
  }
  .rc-slider{
    margin: 0 15px;
    &-rail{
      height: 2px;
      background: #294965;
      border-radius: 8px;
    }
    &-track{
      height: 8px;
      position: absolute;
      top: 3px;    
      background: #294965;
      box-shadow: 0 4px 8px rgba(41, 73, 101, 0.3);
      border-radius: 5px;
    }
    &-handle{
      width: 18px;
      height: 18px;
      top:  2px;
      background: #FDDA2E;
      opacity: 1;
      border: none;
      box-shadow: 0 4px 8px rgba(253, 218, 46, 0.3);
      border-radius: 5px;
    }
  }
`;