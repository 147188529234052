import styled from "styled-components";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledTopSectionWrapper = styled.div`
  padding: 80px 0 0;
  position: relative;
  background: #294965;
  @media screen and (max-width: 992px){
    padding: 40px 0 0;
  }
`;

export const StyledTopSectionContainer = styled(StyledContainer)`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  .image-container{
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 2;
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    .section-content {
      margin-left: 15px;
    }

    .image-container {
      grid-row: -3;
      grid-column: 1;
    }
  }
`;