import React from "react";
import CustomTitle from "../../elements/title/CustomTitle";
import { useTranslation } from "react-i18next";

import partner_01 from "../../../assets/images/partners/partner_02.png"
import partner_02 from "../../../assets/images/partners/partner_01.png"
import partner_03 from "../../../assets/images/partners/partner_04.png"
import partner_04 from "../../../assets/images/partners/partner_03.png"

import { StyledPartnersContainer, StyledPartnersWrapper } from "./styledPartners";
import { StyledSectionContent } from "../../styles/styledSectionContent";
import { StyledButton } from "../../styles/styledButton";
import { NavLink } from "react-router-dom";

const PartnersSection = () => {
  const { t } = useTranslation("home")

  return (
    <StyledPartnersWrapper>
      <StyledPartnersContainer wrapper="content">
        <CustomTitle title={t("partners_title")} color="#FDDF1F"/>
        <div className="partners">
          <div className="partners__item">
            <img src={partner_02} alt=""/>
          </div>
          <div className="partners__item">
            <img src={partner_03} alt=""/>
          </div>
          <div className="partners__item">
            <img src={partner_04} alt=""/>
          </div>
          <div className="partners__item">
            <img src={partner_01} alt=""/>
          </div>
        </div>
        <StyledSectionContent
          side="left"
          inverted
          boldColor="#FDDF1F">
          <div className="section-content">
            <p className="section-content__paragraph">
              {t("partners_paragraph")}
            </p>
          </div>
          <StyledButton
            as={NavLink}
            to="/partners"
            color="main">
            {t("read_more_btn")}
          </StyledButton>
        </StyledSectionContent>
      </StyledPartnersContainer>
    </StyledPartnersWrapper>
  );
};

export default PartnersSection;