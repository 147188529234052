import styled from "styled-components";

export const ScrollToTopButtonWrapper = styled.div`
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 60px;
  right: 60px;
  font-size: 3rem;
  color: #294965;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 5px 1px rgba(255, 255, 255, 0.5);
  border: 2px solid #294965;
  border-radius: 50%;
  transform: rotate(90deg);
  z-index: 9999;
  @media screen and (max-width: 992px) {
    width: 60px;
    height: 60px;
    bottom: 15px;
    right: 40%;
  }
`;