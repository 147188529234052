import React from "react";
import CustomTitle from "../../components/elements/title/CustomTitle";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import BreadcrumbContainer from "../../components/elements/breadcrumbs/BreadcrumbContainer";
import PartnersContainer from "../../components/partners/PartnersContainer";
import { useTranslation } from "react-i18next";

import backgroundTop from "../../assets/images/backgrounds/background1.svg";

import { StyledContainer } from "../../components/styles/styledContainer";
import HelmetContainer from "../../components/elements/helmetContainer/HelmetContainer";

const PartnersPage = () => {

  const { t } = useTranslation("partners")

  return(
    <HelmetContainer title={t("meta_title")} description={t("meta_description")}>
      <SectionBackground background={backgroundTop} height="180"/>
      <StyledContainer>
        <CustomTitle align="center" title={t("title")} mb="55" as="h1"/>
        <BreadcrumbContainer current={t("title")}/>
        <PartnersContainer t={t}/>
      </StyledContainer>
    </HelmetContainer>
  )
}

export default PartnersPage;