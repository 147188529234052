import styled from "styled-components";
import Tabs from "rc-tabs";

export const StyledTabs = styled(Tabs)`
  z-index: 1;

  .rc-tabs-nav-list:after {
    content: "";
    width: 7px;
    height: calc(62px * ${({items_count}) => items_count});
    right: 0;
    position: absolute;
    background: linear-gradient(173.28deg, #AFAFAF 30.45%, #DEDEDE 59.88%, #C3C3C3 69.08%, #BDBBBB 85.48%);
    border-radius: 5px;
  }
`;