import React, { useEffect, useState } from "react";
import Tabs, { TabPane } from "rc-tabs";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import ExampleFormFields from "./elements/ExampleFormFields";
import ExampleButtons from "./elements/ExampleButtons";
import ExampleAlertMessages from "./elements/ExampleAlertMessages";
import ExampleTable from "./elements/ExampleTable";
import ExampleDialog from "./elements/ExampleDialog";

const DEFAULT_TAB = "formFields";

const TAB_LIST = [
  "formFields", "buttons", "alert", "table", "dialog"
];

const ExampleTab = ({match}) => {
  const history = useHistory();

  const urlForTabs = () => {
    let queryParams = queryString.parse(history.location.search);

    if (!queryParams.currentTab || !TAB_LIST.includes(queryParams.currentTab)) {
      return DEFAULT_TAB;
    }

    return queryParams.currentTab;
  };


  const [currentTab, setCurrentTab] = useState(urlForTabs(match));

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    history.push("?currentTab=" + currentTab);
  }, [currentTab]);

  return (
    <Tabs
      defaultActiveKey={currentTab}
      tabPosition="top"
      className="default-tabs default-tabs-top"
      onChange={handleChangeTab}
    >
      <TabPane tab="Form fields" key="formFields">
        <ExampleFormFields />
      </TabPane>
      <TabPane tab="Buttons" key="buttons">
        <ExampleButtons />
      </TabPane>
      <TabPane tab="Alerts" key="alert">
        <ExampleAlertMessages />
      </TabPane>
      <TabPane tab="Table" key="table">
        <ExampleTable />
      </TabPane>
      <TabPane tab="Dialog" key="dialog">
        <ExampleDialog />
      </TabPane>
    </Tabs>
  );
};

export default ExampleTab;