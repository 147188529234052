import React from "react";
import { useTranslation } from "react-i18next";
import HelmetContainer from "../../components/elements/helmetContainer/HelmetContainer";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import backgroundTop from "../../assets/images/backgrounds/background1.svg";
import { StyledContainer } from "../../components/styles/styledContainer";
import CustomTitle from "../../components/elements/title/CustomTitle";
import BreadcrumbContainer from "../../components/elements/breadcrumbs/BreadcrumbContainer";
import Footer from "../../components/elements/footer/Footer";
import AboutUsContainer from "../../components/aboutUs/AboutUsContainer";

const AboutUs = () => {
  const { t } = useTranslation("aboutUs");

  return (
    <HelmetContainer title={t("meta_title")} description={t("meta_description")}>
      <SectionBackground
        background={backgroundTop}
        height="180"
      />
      <StyledContainer>
        <CustomTitle
          align="center"
          valign="center"
          title="About us"
          as="h1"
        />
        <BreadcrumbContainer current="About us" />
        <AboutUsContainer />
      </StyledContainer>
      <Footer />
    </HelmetContainer>
  );
}

export default AboutUs