import { TemplateContainer } from "../../styles/styledContainer";
import RoundedImage from "../../elements/roundedImage/RoundedImage";

import partner_01 from "../../../assets/images/partners/partner_02.png";
import partner_02 from "../../../assets/images/partners/partner_01.png";
import partner_03 from "../../../assets/images/partners/partner_04.png";
import partner_04 from "../../../assets/images/partners/partner_03.png";
import { Helmet } from "react-helmet-async";
import HelmetContainer from "../../elements/helmetContainer/HelmetContainer";

const Partner = ({partner}) => {

  const checkPartner = (id) => {
    switch (id){
      case "1": return partner_02
      case "2": return partner_01
      case "3": return partner_04
      case "4": return partner_03
    }
  }
  const content = partner.content;
  return (
    <HelmetContainer title={partner.meta_title} description={partner.description}>
      <TemplateContainer>
        <div className="aside-image">
          <RoundedImage image={checkPartner(partner.id)} size="230"/>
        </div>
        <div className="content-wrapper">
          <h1 className="content-wrapper__title">
            {partner.title}
          </h1>
          <div className="content-wrapper__paragraph">
            {partner.description}
          </div>
          <ul className="content-wrapper__list">
            {content.map((item) => (
              <li>
                {item.subtitle &&
                  <div className="content-wrapper__subtitle">
                    {item.subtitle}
                  </div>
                }
                {item.list &&
                  <ul className="content-wrapper__sublist">
                    {item.list.map((list_item) => (
                      <li>
                        {list_item.item}
                      </li>
                    ))}
                  </ul>
                }
                {item.paragraph &&
                  <p className="content-wrapper__paragraph">
                    {item.paragraph}
                  </p>
                }
              </li>
            ))}
          </ul>
        </div>
      </TemplateContainer>
    </HelmetContainer>
  );
};

export default Partner;