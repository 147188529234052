import React from "react";
import CustomTitle from "../../elements/title/CustomTitle";
import { useTranslation } from "react-i18next";

import placeholderImage from "../../../assets/images/whatWeDo/placeholderImage.svg";

import { StyledWhatWeDoContainer, StyledWhatWeDoWrapper } from "./styledWhatWeDo";
import { StyledSectionContent } from "../../styles/styledSectionContent";
import { StyledButton } from "../../styles/styledButton";
import { NavLink } from "react-router-dom";

const WhatWeDoSection = () => {
  const { t } = useTranslation("home")

  return (
    <StyledWhatWeDoWrapper>
      <StyledWhatWeDoContainer wrapper="content">
        <CustomTitle title={t("what_we_do_title")} color="#FDDF1F" />
        <div>
          <div className="video">
            <div className="video__preview">
              <img src={placeholderImage} alt="" />
            </div>
          </div>
        </div>
        <StyledSectionContent
          side="left"
          inverted
          boldColor="#FDDF1F"
        >
          <div className="section-content">
            <p>
              {t("what_we_do_paragraph")}
            </p>
          </div>
          <StyledButton
            as={NavLink}
            to='/what-we-do'
            color="main">
            {t("read_more_btn")}
          </StyledButton>
        </StyledSectionContent>
      </StyledWhatWeDoContainer>
    </StyledWhatWeDoWrapper>
  );
};

export default WhatWeDoSection;