import styled from "styled-components";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledAboutWrapper = styled.div`
  padding: 20px 0 60px;
  
  @media screen and (max-width: 992px){
    padding: 20px 0 40px;
  }
`;

export const StyledAboutContainer = styled(StyledContainer)`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 70px minmax(max-content, 620px);
  
  @media screen and (max-width: 992px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    .rounded-image {
      max-width: 450px;
    }
  }
`;