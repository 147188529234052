import React from "react";
import Logo from "../logo/Logo";
import Navigation from "../navigations/Navigation";

import { StyledHeaderContainer, StyledHeaderWrapper } from "./styledHeader";

const Header = () => {

  return (
    <StyledHeaderWrapper>
      <StyledHeaderContainer wrapper="content">
        <Logo />
        <Navigation />
      </StyledHeaderContainer>
    </StyledHeaderWrapper>
  );
};

export default Header;