import styled from "styled-components";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledCustomerExperienceWrapper = styled.div`
  padding-top: ${({pd}) => pd ? `${pd}px`:`100px`};
`;

export const StyledCustomerExperienceContainer = styled(StyledContainer)`
  padding-bottom: 50px;
  .slider-wrapper {
    position: relative;
    .swiper {
      padding: 45px 15px;
      width: 100%;
      height: 100%;
      &-slide {
        text-align: center;
        font-size: 18px;
        background: #F2F1F1;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        .review {
          padding: 25px;
          color: #294965;
          font-size: 18px;
          text-align: start;
          display: grid;
          column-gap: 15px;
          grid-template-columns: 65px 1fr;
          &__user-img {
            width: 65px;
            height: 65px;
            color: #FFFFFF;
            font-size: 36px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #294965;
            border-radius: 50%;
          }
          &__description {
            padding-top: 25px;
          }
          &__author {
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          &__rate {
            font-size: 16px;
            span:not(:last-child) {
              margin-right: 2px;
            }
          }
          &__title {
            padding: 10px 0;
            font-size: 24px;
            font-weight: 600;
          }
          &__content {
            line-height: 140%;
          }
        }
      }

      &-navigation {
        &-next,
        &-prev {
          color: #294965;
          font-size: 35px;
          border: none;
          outline: none;
          position: absolute;
          top: 50%;
          transform: translateY(-30px);
          transition: all .2s ease;
          z-index: 1;
          &:hover {
            transform: translateY(-30px) scale(0.90);
          }
        }
        &-next {
          right: -30px;
        }
        &-prev {
          left: -30px;
        }
      }
    }
  }
  
  @media screen and (max-width: 1600px) {
    .slider-wrapper {
      overflow: hidden;
      .swiper {
        &-navigation {
          &-next {
            right: -10px;
          }
          &-prev {
            left: -10px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .slider-wrapper {
      .swiper {
        &-slide
        {
          min-height: 350px;
          &-active {
            min-height: 380px;
            transform: translateY(-15px);
            background: #294965;
            box-shadow: 0 27px 71px rgba(41, 73, 101, 0.15);
            .review {
              &__user-img {
                color: #FDDD10;
                background: #FFFFFF;
              }
              &__title {
                padding: 10px 0;
                color: #FDDD10;
              }
              &__author,
              &__content {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .slider-wrapper {
      .swiper {
        padding: 30px 15px;
        &-slide {
          height: auto;
          margin-right: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .slider-wrapper {
      .swiper {
        &-slide {
          .review {
            &__author {
              flex-direction: column;
              align-items: flex-start;
            }
            &__rate {
              padding-top: 25px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    padding: 40px 0 20px;
    .slider-wrapper {
      .swiper {
        &-slide {
          .review {
            padding: 20px;
            grid-template-columns: 45px 1fr;
            &__user-img {
              width: 45px;
              height: 45px;
              font-size: 24px;
            }
            &__description {
              padding-top: 15px;
            }
            &__author {
              font-size: 14px;
            }
            &__rate {
              padding-top: 15px;
              font-size: 14px;
            }
            &__title {
              padding: 15px 0;
              font-size: 16px;
            }
            &__content {
              font-size: 12px;
            }
          }
        }
        &-navigation {
          &-next,
          &-prev {
            transform: translateY(-20px);
            &:hover {
              transform: translateY(-20px);
            }
          }
          &-next {
            right: -10px;
          }
          &-prev {
            left: -10px;
          }
        }
      }
    }
  }
`;