import React from "react";
import PartnerCard from "../home/ourPartners/PartnerCard";

import partner_01 from "../../assets/images/partners/partner_02.png";
import partner_02 from "../../assets/images/partners/partner_01.png";
import partner_03 from "../../assets/images/partners/partner_04.png";
import partner_04 from "../../assets/images/partners/partner_03.png";

import { StyledPartnersContainer } from "./styledPartners";

const PartnersContainer = ({ t }) => {
  const partners = t("partners", { returnObjects: true });
  const checkPartner = (id) => {
    switch (id) {
      case "1":
        return partner_02;
      case "2":
        return partner_01;
      case "3":
        return partner_04;
      case "4":
        return partner_03;
    }
  };
  return (
    <StyledPartnersContainer>
      {partners.map((partner) => (
        <PartnerCard
          image={checkPartner(partner.id)}
          key={partner.id}
          url={partner.url}
        />
      ))}
    </StyledPartnersContainer>
  );
};

export default PartnersContainer;