import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Partner from "./partnersDescriptions/Partner";
import { TabPane } from "rc-tabs";

import { StyledPartnersDetailsContainer } from "./styledPartners";
import { StyledTabs } from "../styles/styledTabs";

const PartnersDetailsContainer = ({partners}) => {

  const history = useHistory()

  const url = useParams();
  const [currentTab, setCurrentTab] = useState(url.url)

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    history.push(currentTab);
  }, [currentTab]);

  return(
    <StyledPartnersDetailsContainer>
      <StyledTabs
        defaultActiveKey={currentTab}
        tabPosition="top"
        className="default-tabs default-tabs-top"
        onChange={handleChangeTab}
      >
        {partners.map((partner) => (
          <TabPane tab={partner.title} key={partner.url}>
            <Partner partner={partner}/>
          </TabPane>
        ))}

      </StyledTabs>
    </StyledPartnersDetailsContainer>
  )
}

export default PartnersDetailsContainer;