import React from "react";
import { StyledRoundedImageWrapper } from "./styledRoundedImage";

const RoundedImage = ({ image, size, pd }) => {
  return (
    <div className="rounded-image-wrapper">
      <StyledRoundedImageWrapper size={size} className="rounded-image" pd={pd}>
        <div className="rounded-image__container">
          <img src={image} alt="rounded-image" />
        </div>
      </StyledRoundedImageWrapper>
    </div>
  );
};

export default RoundedImage;