import styled from "styled-components";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledPartnersWrapper = styled.div`
  padding: 165px 0 95px;
  position: relative;
  background: #294965;
  @media screen and (max-width: 992px) {
    padding: 40px 0 35px;
  }
`;

export const StyledPartnersContainer = styled(StyledContainer)`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 70px 1fr;

  .partners {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 260px);
    grid-gap: 30px;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      border-radius: 10px;
      img {
        width: 70%;
        height: 70%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: 100%;
    grid-gap: 30px;
    .partners {
      grid-gap: 15px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .partners {
      grid-template-rows: repeat(2, 210px);
    }
  }
  
  @media screen and (max-width: 576px) {
    .partners {
      grid-template-rows: repeat(2, 110px);
      &__item  {
        padding: 20px; 
        img {
          width: 100%;
        }
      }
    }
  }
`;