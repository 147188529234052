import React, { memo } from "react";
import { StyledBreadcrumbItem, StyledBreadcrumbLink } from "./styledBreadcrumb";

const BreadcrumbItem = ({ as, to, title, handler}) => {
  return (
    <StyledBreadcrumbItem>
      <StyledBreadcrumbLink as={as} to={to} title={title} onClick={handler}>
        {title}
      </StyledBreadcrumbLink>
    </StyledBreadcrumbItem>
  );
};

export default memo(BreadcrumbItem);