import React from "react";

import { StyledButton } from "../../styles/styledButton";
import { StyledDialog } from "./styledModalWindow";

const ConfirmationModal = ({ message, link, title, handler, visible, setVisible }) => {

  return (
    <StyledDialog
      visible={visible}
      wrapClassName="default-modal-wrapper default-modal-wrapper_confirm"
      animation="zoom"
      maskAnimation="fade"
      title={title}
      forceRender={false}
      className="default-modal confirm-modal"
    >
      <div className="default-modal__content confirm-modal__content">
        <p>
          {link}
        </p>
        <p>
          {message}
        </p>
      </div>
      <div className="default-modal__footer">
        <StyledButton
          color="main" onClick={() => {
          setVisible(false);
        }}
        >
          Home
        </StyledButton>
      </div>
    </StyledDialog>
  );

};

export default ConfirmationModal;
