import React, { useEffect, useState } from "react";
import { TabPane } from "rc-tabs";
import { useHistory, useParams } from "react-router-dom";
import InformationTabContent from "./InformationTabContent";

import { StyledTabs } from "../styles/styledTabs";
import { StyledInformationDetailsContainer } from "./styledInformation";

const  InformationDetailsContainer = ({info_items}) => {

  const history = useHistory();

  const url = useParams()

  const [currentTab, setCurrentTab] = useState(url.url)

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    history.push(currentTab);
  }, [currentTab]);

  return(
    <StyledInformationDetailsContainer>
      <StyledTabs
      defaultActiveKey={currentTab}
      tabPosition="left"
      className="default-tabs default-tabs-top"
      onChange={handleChangeTab}
      items_count={info_items.length}
      >
        {info_items.map((info_item, index) => (
          <React.Fragment key={index}>
            <TabPane tab={info_item.title} key={info_item.url}>
              <InformationTabContent info_item={info_item}/>
            </TabPane>
          </React.Fragment>
        ))}
      </StyledTabs>
      <div className="background"/>
    </StyledInformationDetailsContainer>
  )
}

export default InformationDetailsContainer;