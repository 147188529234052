import React from "react";
import { NavLink } from "react-router-dom";

import logo from "../../../assets/images/logo.svg";
import logoInverted from "../../../assets/images/logo-inverted.svg";

import { StyledLogoText, StyledLogoWrapper } from "./styledLogo";

const Logo = ({ inverted }) => {
  return (
    <NavLink to="/">
      <StyledLogoWrapper>
        <img src={inverted ? logoInverted : logo} alt="home4crypto-logo" />
        <StyledLogoText>
          home4crypto
        </StyledLogoText>
      </StyledLogoWrapper>
    </NavLink>
  );
};

export default Logo;