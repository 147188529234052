import React from "react";

import { StyledWhatWeDoContainer } from "./StyledWhatWeDo";

import image from "../../assets/images/whatWeDo/placeholderImage.svg"

const WhatWeDoContainer = ({t}) => {
  const content = t("content", {returnObjects: true});
  return (
    <StyledWhatWeDoContainer>
      <div className="content-wrapper">
        <div className="content-wrapper__image">
          <img src={image} alt="video placeholder"/>
        </div>
        <div className="content-wrapper__title">
          {t("description")}
        </div>
        <p className="content-wrapper__sub-description">{t("sub_description")}</p>
        {content.map((item) => (
          <>
            {item.subtitle &&
              <div className="content-wrapper__subtitle">
                {item.subtitle}
              </div>
            }
            {item.list &&
              <ul className="content-wrapper__sublist">
                {item.list.map((list_item) => (
                  <li>
                    {list_item.item}
                  </li>
                ))}
              </ul>
            }
            {item.paragraph &&
              <p className="content-wrapper__paragraph">
                {item.paragraph}
              </p>
            }
          </>
        ))}
      </div>
    </StyledWhatWeDoContainer>
  );
};

export default WhatWeDoContainer;