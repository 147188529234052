import styled from 'styled-components';

const type = {
  type: ''
}

const changeType = (type, border) => {
  switch (type) {
    case 'error' :
      return `
        ${border &&  "border: 1px solid #FE6A74"};
        background-color: #FFECEE;
        &:before {
          content: '\\e918';
          color: #FE6A74;
          background-color: #fbc8ce;
        }
      `;
    case 'success' :
      return `
        ${border &&  "border: 1px solid #4fba6e"};
        background-color: #e3f7e4;
        &:before {
          content: '\\e911';
          color: #4fba6e;
          background-color: #d2efda;
        }
      `;
    case 'info' :
      return `
        ${border &&  "border: 1px solid #3286d9"};
        background-color: #e6f7ff;
        &:before {
          content: '\\e924';
          color: #3286d9;
          background-color: #bae9ff;
        }
      `;
    default :
      return `
        ${border &&  "border: 1px solid #efc100"};
        background-color: #fdf3c9;
        &:before {
          content: '\\e91e';
          color: #fdf3c9;
          background-color: #efc100;
        }
      `;
  }
}

export const StyledAlertWrapper = styled('div', type)`
  ${({center}) => center && 'max-width: 1110px'};
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  padding: 15px 15px 15px 55px;
  color: #202020;
  border-radius: 5px;
  position: relative;
  
  &:before {
    width: 25px;
    height: 25px;
    font-size: 14px;
    font-family: 'default-icon', serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 14px;
    left: 15px;
  }
  ${({type, border}) => changeType(type, border)}
`;