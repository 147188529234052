import React from "react";
import { Helmet } from "react-helmet-async";

import {
  StyledCol,
  StyledScrollTable,
  StyledTable,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow
} from "../../styles/styledTable";
import { StyledMainDescription, StyledMainTitle } from "../../styles/styledTitle";

const ExampleTable = () => {
  return (
    <div>
      <Helmet>
        <title>Example Table • ITLab-Studio</title>
      </Helmet>

      <StyledMainTitle size="18" mb="15">
        Example Table
      </StyledMainTitle>

      <StyledMainDescription>
        Scrollable table:
      </StyledMainDescription>
      <StyledScrollTable>
        <StyledTable width="768">
          <StyledTableHead col="5" scroll="auto">
            <div>
              #
            </div>
            <div>
              First name
            </div>
            <div>
              Last name
            </div>
            <div>
              Birthday
            </div>
            <div>
              Country
            </div>
          </StyledTableHead>
          <StyledTableBody>
            <StyledTableRow col="5" scroll="auto">
              <StyledCol data-title="#">
                1
              </StyledCol>
              <StyledCol data-title="First name">
                Eric
              </StyledCol>
              <StyledCol data-title="Last name">
                Turner
              </StyledCol>
              <StyledCol data-title="Age">
                03-08-1990
              </StyledCol>
              <StyledCol data-title="Country">
                Canada
              </StyledCol>
            </StyledTableRow>
            <StyledTableRow col="5" scroll="auto">
              <StyledCol data-title="#">
                2
              </StyledCol>
              <StyledCol data-title="First name">
                Aaron
              </StyledCol>
              <StyledCol data-title="Last name">
                Simmons
              </StyledCol>
              <StyledCol data-title="Age">
                05-07-2000
              </StyledCol>
              <StyledCol data-title="Country">
                USA
              </StyledCol>
            </StyledTableRow>
            <StyledTableRow col="5" scroll="auto">
              <StyledCol data-title="#">
                3
              </StyledCol>
              <StyledCol data-title="First name">
                Tristan
              </StyledCol>
              <StyledCol data-title="Last name">
                Horton
              </StyledCol>
              <StyledCol data-title="Age">
                25-12-2000
              </StyledCol>
              <StyledCol data-title="Country">
                USA
              </StyledCol>
            </StyledTableRow>
          </StyledTableBody>
        </StyledTable>
      </StyledScrollTable>

      <StyledMainDescription mt="20">
        Reversible table:
      </StyledMainDescription>
        <StyledTable>
          <StyledTableHead col="5">
            <div>
              #
            </div>
            <div>
              First name
            </div>
            <div>
              Last name
            </div>
            <div>
              Birthday
            </div>
            <div>
              Country
            </div>
          </StyledTableHead>
          <StyledTableBody>
            <StyledTableRow col="5">
              <StyledCol data-title="#">
                1
              </StyledCol>
              <StyledCol data-title="First name">
                Eric
              </StyledCol>
              <StyledCol data-title="Last name">
                Turner
              </StyledCol>
              <StyledCol data-title="Age">
                03-08-1990
              </StyledCol>
              <StyledCol data-title="Country">
                Canada
              </StyledCol>
            </StyledTableRow>
            <StyledTableRow col="5">
              <StyledCol data-title="#">
                2
              </StyledCol>
              <StyledCol data-title="First name">
                Aaron
              </StyledCol>
              <StyledCol data-title="Last name">
                Simmons
              </StyledCol>
              <StyledCol data-title="Age">
                05-07-2000
              </StyledCol>
              <StyledCol data-title="Country">
                USA
              </StyledCol>
            </StyledTableRow>
            <StyledTableRow col="5">
              <StyledCol data-title="#">
                3
              </StyledCol>
              <StyledCol data-title="First name">
                Tristan
              </StyledCol>
              <StyledCol data-title="Last name">
                Horton
              </StyledCol>
              <StyledCol data-title="Age">
                25-12-2000
              </StyledCol>
              <StyledCol data-title="Country">
                USA
              </StyledCol>
            </StyledTableRow>
          </StyledTableBody>
        </StyledTable>
    </div>
  );
};

export default ExampleTable;