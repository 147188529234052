import React from "react";
import InformationContainer from "../../components/information/InformationContainer";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import CustomTitle from "../../components/elements/title/CustomTitle";
import Footer from "../../components/elements/footer/Footer";
import BreadcrumbContainer from "../../components/elements/breadcrumbs/BreadcrumbContainer";
import { useTranslation } from "react-i18next";

import backgroundTop from "../../assets/images/backgrounds/background1.svg";

import { StyledInformationWrapper } from "../../components/information/styledInformation";
import { Helmet } from "react-helmet-async";
import HelmetContainer from "../../components/elements/helmetContainer/HelmetContainer";

const InformationPage = () => {
  const { t } = useTranslation("information")

  return (
    <HelmetContainer title={t("meta_title")} description={t("meta_description")}>
      <SectionBackground background={backgroundTop} height="180"/>
      <StyledInformationWrapper>
        <CustomTitle align="center" title={t("title")} mb="55" as="h1"/>
        <BreadcrumbContainer current={t("title")}/>
        <InformationContainer t={t}/>
      </StyledInformationWrapper>
      <Footer/>
    </HelmetContainer>
  );
};

export default InformationPage;