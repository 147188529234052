import React from "react";
import AboutSection from "../../components/home/aboutUs/AboutSection";
import WhatWeDoSection from "../../components/home/whatWeDo/WhatWeDoSection";
import PartnersSection from "../../components/home/ourPartners/PartnersSection";
import HowItWorksSection from "../../components/home/howItWorks/HowItWorksSection";
import LetsWorkTogetherSection from "../../components/home/letsWorkTogether/LetsWorkTogetherSection";
import TopSection from "../../components/home/top/TopSection";
import CustomerExperienceSection from "../../components/home/customerExperience/CustomerExperienceSection";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import Footer from "../../components/elements/footer/Footer";

import whatWeDoBackground from "../../assets/images/backgrounds/background1.svg";
import partnersBackground from "../../assets/images/backgrounds/background2.svg";
import letsWorkTogetherBackground from "../../assets/images/backgrounds/background3.svg";
import backgroundTop from "../../assets/images/backgrounds/background-top.svg";
import HelmetContainer from "../../components/elements/helmetContainer/HelmetContainer";
import { useTranslation } from "react-i18next";

const HomePage = () => {

  const { t } = useTranslation("home");

  return (
    <HelmetContainer
      title={t("meta_title")}
      description={t("meta_description")}
    >
      <TopSection />
      <SectionBackground
        background={backgroundTop}
        height="260"
        pos="20"
      />
      <AboutSection />
      <WhatWeDoSection />
      <SectionBackground
        background={whatWeDoBackground}
        height="180"
      />
      <HowItWorksSection />
      <SectionBackground
        background={partnersBackground}
        height="155"
      />
      <PartnersSection />
      <CustomerExperienceSection />
      <SectionBackground
        background={letsWorkTogetherBackground}
        height="205"
        mob_height="185"
      />
      <LetsWorkTogetherSection />
      <Footer inverted="inverted" />
    </HelmetContainer>
  );
};

export default HomePage;