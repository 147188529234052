import styled from "styled-components";

export const StyledScrollTable = styled.div`
  overflow: auto;
  overflow-scrolling: touch;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
    background-color: #e5e2dc;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track{
    background-color: #e5e2dc;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    height: 16px;
    border: 2px solid #e5e2dc;
    background-color: #c8b19b;
    //background: linear-gradient(40deg, #29DEAE -20.66%, #277661 68.48%);
    border-radius: 12px;
  }
`;

export const StyledTable = styled.div`
  margin-bottom: 20px;
  font-size: ${({ size }) => size + "px"};
  ${({ width }) => width && `min-width: ${width}px`};
  ${({ width }) => width && "width: 100%"};
  ${({ width }) => width && "overflow-x: hidden;"};
`;

export const StyledTableHead = styled.div`
  padding: 8px 10px;
  color: #444;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
  grid-column-gap: 15px;
  font-weight: 600;
  @media screen and (max-width: 992px) {
    display: ${({ scroll }) => scroll === "auto" ? "grid" : "none"};
  }
`;

export const StyledTableBody = styled.div``;

export const StyledTableRow = styled.div`
  ${({cursor}) => cursor && `cursor: ${cursor}`};
  padding: 8px 10px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
  grid-column-gap: 15px;
  border-top: 1px solid #E5E5E5;
  &:last-child {
    border-bottom: 1px solid #E5E5E5;
  }
  &:hover {
    color: #a99581;
    background-color: rgba(160, 150, 130, 0.1);
  }
  @media screen and (max-width: 992px) {
    grid-template-rows: ${({ scroll }) => scroll === "auto" ? "1fr" : `repeat(${({ col }) => col}, minmax(50px, auto))`};
    grid-template-columns: ${({ scroll }) => scroll === "auto" ? `repeat(${({ col }) => col}, 150px)` : "1fr"};
    ${({ scroll }) => scroll && `
       & > div {
        margin-bottom: inherit;
        padding-top: inherit;
         &:before {display: none}; 
        }
    `};
  }
`;

export const StyledCol = styled.div`
  display: ${({ inline }) => inline ? "inline-flex" : "grid"};
  align-items: center;
  position: relative;
  &:before {
    content: attr(data-title) ':';
    font-size: 11px;
    letter-spacing: 0.4px;
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    @media screen and (max-width: 992px) {
      display: block;
    }
  }
  @media screen and (max-width: 992px) {
    margin-bottom: 5px;
    padding-top: 20px;
    align-items: start;
  }
`;
