import React from "react";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import CustomTitle from "../../components/elements/title/CustomTitle";
import PartnersDetailsContainer from "../../components/partners/PartnersDetailsContainer";
import Footer from "../../components/elements/footer/Footer";
import BreadcrumbContainer from "../../components/elements/breadcrumbs/BreadcrumbContainer";
import { useTranslation } from "react-i18next";

import backgroundTop from "../../assets/images/backgrounds/background1.svg";

import { StyledContainer } from "../../components/styles/styledContainer";
import HelmetContainer from "../../components/elements/helmetContainer/HelmetContainer";

const PartnersDetails = () => {
  const { t } = useTranslation("partners")
  const partners = t("partners", {returnObjects: true});

  return(
    <HelmetContainer>
      <SectionBackground background={backgroundTop} height="180"/>
      <StyledContainer>
        <CustomTitle mb="55" title={t("title")} align="center" as="h1"/>
        <BreadcrumbContainer current={t("title")} link="/partners"/>
        <PartnersDetailsContainer partners={partners}/>
      </StyledContainer>
      <Footer/>
    </HelmetContainer>
  )
}

export default PartnersDetails;