import React from "react";
import ExampleTab from "../../components/examples/ExampleTab";

import { StyledContainer } from "../../components/styles/styledContainer";
import { StyledMainTitle } from "../../components/styles/styledTitle";
import { StyledExamplesWrapper } from "../../components/examples/styledExamples";

const ExamplePage = () => {
  return (
    <StyledContainer>
      <StyledExamplesWrapper>
        <StyledMainTitle mb="20" size="22" position="center">
          Example components
        </StyledMainTitle>
        <ExampleTab />
      </StyledExamplesWrapper>
    </StyledContainer>
  );
};

export default ExamplePage;