import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import BreadcrumbItem from "./BreadcrumbItem";

import { StyledBreadcrumb, StyledBreadcrumbContainer } from "./styledBreadcrumb";
import { useTranslation } from "react-i18next";

const BreadcrumbContainer = ({ current, previous, link }) => {

  const { t } = useTranslation("breadcrumb")

  return (
    <StyledBreadcrumbContainer wrapper="content">
      <StyledBreadcrumb>
        {previous ?
          <BreadcrumbItem as={NavLink} to={link} title={previous}/> :
          <BreadcrumbItem as={NavLink} to="/" title={t("home")}/>
        }
        <BreadcrumbItem as="span" title={current}/>
      </StyledBreadcrumb>
    </StyledBreadcrumbContainer>
  );
};

export default memo(BreadcrumbContainer);