import styled from "styled-components";
import { StyledContainer, StyledContentWrapper } from "../styles/styledContainer";
import background from "../../assets/images/information/background.svg"

export const StyledInformationWrapper = styled(StyledContainer)`
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
`;

export const StyledInformationContainer = styled(StyledContentWrapper)`
  padding-bottom: 100px;
  display: grid;
  grid-gap: 45px;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width: 992px){
    padding-bottom: 40px;
    grid-gap: 10px;
    grid-template-columns: 1fr;  
  }
`;

export const StyledInformationDetailsContainer = styled.div`
  padding: 30px 0 115px;
  display: grid;
  position: relative;
  background: #F2F1F1;
  border-radius: 10px;
  .default-tabs-top{
    grid-template-columns: 335px 1fr;
    grid-template-rows: auto;
  }
  .background{
    background-image: url(${background});
    background-size: cover;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 628px;
  }
  @media screen and (max-width: 992px){
    .default-tabs-top{
      grid-template-columns: 100%;
      grid-template-rows: minmax(40px,auto) auto;
    }
  }
`;