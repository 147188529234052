import React from "react";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import CustomTitle from "../../components/elements/title/CustomTitle";
import Footer from "../../components/elements/footer/Footer";
import InformationDetailsContainer from "../../components/information/InformationDetailsContainer";
import BreadcrumbContainer from "../../components/elements/breadcrumbs/BreadcrumbContainer";
import { useTranslation } from "react-i18next";

import backgroundTop from "../../assets/images/backgrounds/background1.svg";

import { StyledInformationWrapper } from "../../components/information/styledInformation";
import { Helmet } from "react-helmet-async";

const InformationDetails = () => {
  const { t } = useTranslation("information");
  const info_items = t("info_items", {returnObjects: true});
  return (
    <>
      <SectionBackground background={backgroundTop} height="180"/>
      <StyledInformationWrapper>
        <CustomTitle mb="55" align="center" category={t("category")} title={t("custom_title")} />
        <BreadcrumbContainer current={t("breadcrumb_title")} previous={t("title")} link="/information"/>
        <InformationDetailsContainer info_items={info_items}/>
      </StyledInformationWrapper>
      <Footer/>
    </>
  );
};

export default InformationDetails;