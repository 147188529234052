import styled from "styled-components";

import { StyledContainer } from "../../styles/styledContainer";
import { StyledSelect } from "../../styles/styledSelect";

export const StyledHeaderWrapper = styled.header`
  padding: 12px 0 0;
  color: #fff;
  background-color: #294965;
  
  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }
`;

export const StyledHeaderContainer = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const StyledLangSelect = styled(StyledSelect)`
  margin: 0 auto;
  font-weight: 600;
  cursor: pointer;
  
  input {
    display: none;
  }
  
  .lang-select {
    z-index: 1;
    transition: all .3s ease;
    background-color: transparent;
    box-shadow: none;
    .lang-select__item {
      width: 100%;
      margin: 0;
      padding: 0;
      color: #fff;
      display: flex;
      align-items: center;
      transition: all .3s ease;
    }
    .rc-select-selector {
      min-height: auto;
      padding: 0;
    }
    .rc-select-selection-item {
      padding: 0 25px 0 0;
    }
    .rc-select-arrow {
      &-icon {
        top: 4px;
        left: 0;
        transition: all .3s ease;
      }
    }
    &:hover {
      .lang-select__item,
      .rc-select-arrow-icon {
        color: #FDDD10;
      }
    }
  }

  .rc-select-dropdown {
    .rc-select-item-option {
      .lang-select__item {
        color: #294965;
      }
      &:hover {
        .lang-select__item {
          color: #fff;
        }
      }
    }
    .rc-select-item-option-selected {
      background: #294965 !important;
      .lang-select__item {
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 992px) {
    padding-left: 10px;
    padding-bottom: 10px;
    .rc-select-dropdown {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
`;