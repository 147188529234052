import React from "react";
import SectionBackground from "../../components/elements/sectionBackground/SectionBackground";
import WhatWeDoContainer from "../../components/whatWeDo/WhatWeDoContainer";
import CustomTitle from "../../components/elements/title/CustomTitle";
import BreadcrumbContainer from "../../components/elements/breadcrumbs/BreadcrumbContainer";
import Footer from "../../components/elements/footer/Footer";
import { useTranslation } from "react-i18next";

import { StyledWhatWeDoWrapper } from "../../components/whatWeDo/StyledWhatWeDo";

import backgroundTop from "../../assets/images/backgrounds/background1.svg";
import { Helmet } from "react-helmet-async";
import HelmetContainer from "../../components/elements/helmetContainer/HelmetContainer";

const WhatWeDoPage = () => {

  const { t } = useTranslation("whatWeDo");

  return(
    <HelmetContainer title={t("meta_title")} description={t("meta_description")}>
      <SectionBackground background={backgroundTop} height="180"/>
      <StyledWhatWeDoWrapper>
        <CustomTitle align="center" title={t("title")} mt="15" mb="15" as="h1"/>
        <BreadcrumbContainer current={t("title")}/>
        <WhatWeDoContainer t={t}/>
      </StyledWhatWeDoWrapper>
      <Footer/>
    </HelmetContainer>
  )
}

export default WhatWeDoPage;