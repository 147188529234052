import styled from "styled-components"
import { StyledContentWrapper } from "../styles/styledContainer";

export const StyledPartnersContainer = styled(StyledContentWrapper)`
  padding-bottom: 100px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 992px){
    padding-bottom: 40px;
    grid-gap: 40px;
    grid-template-columns: 1fr;
  }
`;

export const PartnerCardWrapper = styled.div`
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 27px 71px rgba(41, 73, 101, 0.2);
  border-radius: 10px;
  &:hover{
    background: #294965;
    transition: 0.2s;
    img{
      filter: brightness(0) invert(100%);
    }
  }
`;

export const StyledPartnersDetailsContainer = styled.div`
  padding: 30px 0 115px;
  display: grid;
  position: relative;
  background: #F2F1F1;
  border-radius: 10px;
  .default-tabs-top{
    grid-template-rows: auto;
    grid-gap: 0;
  }
  .rc-tabs-nav{
  width: 0;
  height: 0;
  }
`;