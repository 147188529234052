import styled from "styled-components";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledLetsWorkTogetherWrapper = styled.div`
  padding: 165px 0;
  position: relative;
  background: #294965;
  @media screen and (max-width: 992px) {
    padding: 40px 0 35px;
  }
`;

export const StyledLetsWorkTogetherContainer = styled(StyledContainer)`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 70px minmax(max-content, 550px);
  form {
    width: 100%;
    .form-actions {
      margin-top: 35px;
    }
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    form {
      .form-actions {
        margin-top: 30px;
        text-align: center;
      }
    }
    .rounded-image {
      max-width: 450px;
    }
  }
`;