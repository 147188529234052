import styled from "styled-components";

const colorButton = {
  color: String,
  border: Boolean
};

const styleButton = (color) => {
  switch (color) {
    case "main":
      return `
        background-image: linear-gradient(282deg, #FDDA2E 0%, #FFF8D6  46%, #FDE56C  57%, #FDDA2E 73%);
        &:hover {
          background-position: center;
        }
    `;
    case "neutral":
      return `
        background-image: linear-gradient(282.68deg, #AFAFAF 7.05%, #F9F9F9 45.87%, #E6E6E6 57.34%, #BDBBBB 72.79%);;
        &:hover {
          background-position: center;
        }
    `;
    default:
      return `
        color: #294965;
        background: transparent;
      `;
  }
};

export const StyledButton = styled("button", colorButton)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ width }) => width && `width: ${width}%`};
  ${({ size }) => size && `font-size: ${size}px`};
  padding: 13px 40px;
  color: #294965;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-size: 200% auto;
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: all .3s ease;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    transform: scale(1) !important;
  `};

  ${({ color, border }) => styleButton(color, border)}
  &:active {
    transform: scale(0.948) translateZ(0);
  }
`;