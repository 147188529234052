import styled from "styled-components";

export const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 90px;
    height: 90px;
    object-position: center;
    object-fit: contain;
  }

  @media screen and (max-width: 1200px) {
    img {
      width: 55px;
      height: 55px;
    }
  }
`;

export const StyledLogoText = styled.div`
  padding-left: 30px;
  font-size: 24px;
  font-weight: 700;

  @media screen and (max-width: 1200px) {
    padding-left: 10px;
    font-size: 14px;
  }
`;