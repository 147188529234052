import React from "react";
import Select, { Option } from "rc-select";

import { StyledLangSelect } from "./styledHeader";
import { useCookies } from "react-cookie";
import i18next from "i18next";
import { supportedLanguages } from "../../../utils/consts";

const LangSelect = ({ id }) => {
  const [cookies] = useCookies();

  const handleChangeLang = (value) => {
    i18next.changeLanguage(value);
  };

  return (
    <StyledLangSelect id={id}>
      <Select
        className="custom-select lang-select"
        name="lang"
        defaultValue={null}
        value={cookies["i18next"] ?? null}
        onChange={(value) => handleChangeLang(value)}
        getPopupContainer={() => document.getElementById(id)}
        dropdownMatchSelectWidth={false}
        direction="rtl"
      >
        {Object.entries(supportedLanguages).map(([key,value]) => {
          return (
          <Option value={key} key={key}>
            <div className="option-select-item lang-select__item">
              {value.description}
            </div>
          </Option>
        )
        })}
      </Select>
    </StyledLangSelect>
  );
};

export default LangSelect;