import React from "react";
import CustomTitle from "../../elements/title/CustomTitle";
import RoundedImage from "../../elements/roundedImage/RoundedImage";
import { useTranslation } from "react-i18next";

import StepImage1 from "../../../assets/images/howItWorks/step1.svg";
import StepImage2 from "../../../assets/images/howItWorks/step2.svg";
import StepImage3 from "../../../assets/images/howItWorks/step3.svg";
import StepImage4 from "../../../assets/images/howItWorks/step4.svg";

import { StyledHowItWorksContainer, StyledHowItWorksWrapper } from "./styledHowItWorks";

const HowItWorksSection = ({ pd }) => {
  const { t } = useTranslation("home");
  const hiw_blocks = t("hiw_blocks", { returnObjects: true });

  const checkImage = (id) => {
    switch(id) {
      case "1": return(StepImage1)
      case "2": return(StepImage2)
      case "3": return(StepImage3)
      case "4": return(StepImage4)
    }
  }

  return (
    <StyledHowItWorksWrapper id="how-it-works" pd={pd}>
      <StyledHowItWorksContainer wrapper="content">
        <CustomTitle title={t("hiw_title")}/>
        <div className="steps-container">
          {hiw_blocks.map((block) => (
            <>
              <div key={"text-"+block.id} className="steps-container__item steps-container__item-text" id={"text" + block.id}>
                <div key={"counter"+block.id} className="steps-container__counter">
                  {block.id}
                </div>
                <div key={"title"+block.id} className="steps-container__title">
                  {block.title}
                </div>
                <div key={"content"+block.id} className="steps-container__content">
                  {block.content}
                </div>
              </div>
              <div key={"image"+block.id} className="steps-container__item" id={"image"+block.id}>
                <RoundedImage image={checkImage(block.id)}/>
              </div>
            </>
          ))}
        </div>
      </StyledHowItWorksContainer>
    </StyledHowItWorksWrapper>
  );
};

export default HowItWorksSection;