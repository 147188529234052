import React from "react";
import CustomTitle from "../../elements/title/CustomTitle";
import RoundedImage from "../../elements/roundedImage/RoundedImage";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

import { StyledSectionContent } from "../../styles/styledSectionContent";
import { StyledTopSectionContainer, StyledTopSectionWrapper } from "./styledTopSection";
import { StyledButton } from "../../styles/styledButton";

import image from "../../../assets/images/headerSection/headerSectionImage.svg";

const TopSection = () => {

  const { t } = useTranslation("home");

  return (
    <StyledTopSectionWrapper>
      <StyledTopSectionContainer wrapper="content">
        <div>
          <CustomTitle
            as="h1"
            title={t("top_title")}
            height="100%"
            color="#FFFFFF"
          />
        </div>
        <StyledSectionContent
          side="left"
          inverted
        >
          <div className="section-content">
            <p className="section-content__paragraph">
              {t("top_content")}
            </p>
          </div>
          <StyledButton
            as={Link}
            to="/#contacts"
            color="main">
            {t("contact_btn")}
          </StyledButton>
        </StyledSectionContent>

        <div className="image-container">
          <RoundedImage image={image} />
        </div>
      </StyledTopSectionContainer>
    </StyledTopSectionWrapper>
  );
};

export default TopSection;