import Logo from "../logo/Logo";
import { NavLink } from "react-router-dom";

import { StyledFooterContainer, StyledFooterWrapper } from "./styledFooter";
import { StyledNavList } from "../navigations/styledNavigations";
import ScrollToTopButton from "../scrollToTopButton/ScrollToTopButton";

const Footer = ({inverted}) => {
  const currentYear = (new Date().getFullYear());

  return (
    <>
      <StyledFooterWrapper inverted={inverted}>
        <StyledFooterContainer inverted={inverted} wrapper="content" className="footer-nav">
          <Logo inverted={inverted && "inverted"}/>
          {/*TODO заменить на новый адрес*/}
          {/*<div className="footer-nav-left">*/}
          {/*  <div className="footer-nav__item">*/}
          {/*    CRPT TRT OÜ*/}
          {/*  </div>*/}
          {/*  <div className="footer-nav__address">*/}
          {/*    <p>*/}
          {/*      Harju maakond, Tallinn,*/}
          {/*    </p>*/}
          {/*    <p>*/}
          {/*      Kristiine linnaosa, Marja tn 7, 10617*/}
          {/*    </p>*/}
          {/*    <p>*/}
          {/*      Registration number: 14574124*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <StyledNavList className="footer-nav-right">
            <li>
              <NavLink to="/faq" className="footer-nav__item">
                FAQ
              </NavLink>
            </li>
            <li>
              <NavLink to="/user-consent" className="footer-nav__item">
                User consent
              </NavLink>
            </li>
            <li>
              <NavLink to="/privacy-policy" className="footer-nav__item">
                Privacy Policy
              </NavLink>
            </li>
          </StyledNavList>
        </StyledFooterContainer>
        <small className="footer-copyright">
          © 2021—{currentYear} home4crypto.com
        </small>
        <ScrollToTopButton/>
      </StyledFooterWrapper>
    </>
  );
};

export default Footer;