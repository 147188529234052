import styled from 'styled-components';
import { StyledContainer } from "../../styles/styledContainer";

export const StyledBreadcrumbContainer = styled(StyledContainer)`
  padding: 10px 0;
`;

export const StyledBreadcrumb = styled.ul`
  margin-bottom: 15px;
  display: flex;
  span {
    color: #000000;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const StyledBreadcrumbItem = styled.li`
  padding-right: 35px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  line-height: 18px;
  white-space: nowrap;
  position: relative;
  &:after {
    content: "/";
    color: #F6D129;
    font-size: 18px;
    font-weight: 900;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  &:last-child {
    max-width: 70%;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0;
    &:after {
      display: none;
    }
  }
`;

export const StyledBreadcrumbLink = styled.a`
  color: #000000;
`;
