import React from 'react';
import Spinner from './Spinner';

import { StyledFragmentSpinner } from './styledSpinner';

const FragmentSpinner = ({position, radius, background}) => {
  return (
    <StyledFragmentSpinner className="fragment-spinner" position={position} radius={radius} background={background}>
      <Spinner color="#FFFFFF" type="moonLoader" size="35px" />
    </StyledFragmentSpinner>
  );
};

export default FragmentSpinner;