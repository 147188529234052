import styled from "styled-components";
import { StyledContainer, TemplateContainer } from "../styles/styledContainer";

export const StyledWhatWeDoWrapper = styled(StyledContainer)``;

export const StyledWhatWeDoContainer = styled(TemplateContainer)`
  padding: 45px 0 90px;
  display: flex;
  justify-content: center;

  .content-wrapper {
    max-width: 750px;
    color: #294965;
    font-size: 18px;
    line-height: 24px;

    &__title {
      display: grid;
      grid-template-columns: 36px 1fr;
      &:before {
        content: "";
        width: 7px;
        height: 100%;
        background: linear-gradient(173.28deg, #AFAFAF 30.45%, #DEDEDE 59.88%, #C3C3C3 69.08%, #BDBBBB 85.48%);
        border-radius: 5px;
      }
    }
    &__sub-description {
      padding: 15px 0;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 15px 15px 30px;
    .content-wrapper {
      &__image {
        img {
          max-width: 100%;
        }
      }

      font-size: 14px;
      line-height: 18px;

      &__title {
        padding: 15px 0;
        font-size: 14px;
        line-height: 18px;
        grid-template-columns: 15px 1fr;

        &:before {
          width: 5px;
        }
      }
    }
  }
`;