import styled from "styled-components";

export const StyledRoundedImageWrapper = styled.div`
  max-width: ${({size}) => size+"px" || "645px"};
  .rounded-image__container {
    padding-top: 100%;
    background: #F9F9F9;
    border-radius: 50%;
    position: relative;
    img {
      width: 70%;
      padding: ${({pd}) => pd+"px"};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  @media screen and (max-width: 992px){
    margin: 0 auto;
  }
`;
